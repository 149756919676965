import React from 'react'
import Biography from "../components/userBiography/Biography";
import Certificates from "../components/certificate/Certificates";
import {Grid} from "@mui/material";
import Bookings from "../components/booking/Bookings";
import Assignments from "../components/assignements/Assignments";

export default function MyPresentation() {

  return (
      <Grid container
            justifyContent="center"
            spacing={3}
            direction="row"
            alignItems="stretch"
      >
        <Grid item xs={12} md={12} lg={5} xl={5}>
          <Grid container
                spacing={3}
                direction="column"
                alignItems="stretch"
          >
            <Grid item>
              <Certificates/>
            </Grid>
            <Grid item>
              <Bookings/>
            </Grid>
            <Grid item>
              <Assignments/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={5} xl={5}>
          <Biography/>
        </Grid>
      </Grid>
  );
}
