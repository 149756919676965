import React from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Links from "../components/links/Links";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

}));

export default function LinkContainer() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="stretch">
        <Grid item xs={12} lg>
          <Links/>
        </Grid>
      </Grid>
    </div>
  );
}
