import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import DiveSite from "./DiveSite";
import DiveSiteService from "../../services/divesite-service";
import {
  Button,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  } from "@mui/material";
import authService from "../../services/auth-service";
import AddIcon from "@mui/icons-material/Add";
import {useSnackbar} from "notistack";
import CreateDiveSite from "./CreateDiveSite";
import Loading from "../Loading";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    maxHeight: "80vh"
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));

export default function DiveSites() {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState([]);
  const [create, setCreate] = React.useState(false);
  const [diveSite, setDiveSite] = useState([])
  const [loading, setLoading] = React.useState(false);
  const isUserLeader  = authService.isLeader();
  const isUserAdministrator = authService.isAdministrator();
  const {enqueueSnackbar} = useSnackbar();



  const handleOpen = (divesite) => {
    DiveSiteService.get(divesite.id).then(fetchedData => {
      setDiveSite(fetchedData)
      setOpen(true)
    })
  }

  const handleClose = () => {
    setOpen(false)
    setCreate(false)
  }

  const addButton = () => {
    if (isUserLeader || isUserAdministrator) {
      return <Button size={"large"} variant="text" onClick = {setCreate} ><AddIcon/></Button>;
    }
  }

  const handleCreate = ({title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType}) => {
    DiveSiteService.create({title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType})
      .then(() => {
        enqueueSnackbar(title + ' sparat', {variant: 'success'})
        load()
        setCreate(false)
      })
  }

  const handleEdit = ({id, title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType}) => {
    DiveSiteService.update({id, title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType})
      .then(() => {
        enqueueSnackbar(title + " sparat", {variant: "success"});
        load()
      })
  }

  const handleDelete = () => {
    DiveSiteService.remove(diveSite.id)
      .then(() => {
        enqueueSnackbar("Dykplatsen är nu borttagen", {variant: "success"})
        load();
      })
    setOpen(false);
  }

  const load = () => {
    setLoading(true)
    DiveSiteService.all().then(newDiveSites => {
      setData(newDiveSites);
      setLoading(false);
    })
  }

  useEffect(() => {
    DiveSiteService.all().then(fetchedDivesites => {
      setData(fetchedDivesites);
    })
  }, []);

  useEffect(() => {
    load()
  }, []);


  return (
    <Paper sx={{overflow: 'hidden'}}>
      <div className={classes.topRow}>
        <Typography className={classes.heading}>Dykplatser</Typography>
        {addButton()}
      </div>
      <Loading loading={loading} />
      <TableContainer component={Paper} sx={{maxHeight: "80vh"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Dykplats</TableCell>
              <TableCell>Typ</TableCell>
              <Hidden smDown><TableCell>Nivå</TableCell></Hidden>
              <TableCell>Maxdjup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((divesite) => (
                <TableRow hover key={divesite.id} onClick={() => handleOpen(divesite)}>
                  <TableCell>
                    {divesite.name}
                  </TableCell>
                  <TableCell>{divesite.type}</TableCell>
                  <Hidden smDown><TableCell>{divesite.level}</TableCell></Hidden>
                  <TableCell>{divesite.maxDepth}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DiveSite diveSiteId={diveSite.id} handleClose={handleClose} handleEdit={handleEdit} handleDelete={handleDelete} open={open}/>
      <CreateDiveSite open={create} handleClose={handleClose} handleCreate={handleCreate}/>
    </Paper>
  );
}


