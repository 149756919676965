import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth-service";

const userId = authService.getUserId();
const API_URL = process.env.REACT_APP_API_URL + `/api/${userId}/ice`;

const all = () => {
  return axios
    .get(API_URL, {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const get = (id) => {
  return axios
    .get(API_URL + "/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data
    })
}

const create = (name, relation, primaryPhone, secondaryPhone) => {
  return axios
    .post(API_URL, {name: name, relation: relation, primaryPhone: primaryPhone, secondaryPhone: secondaryPhone},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const update = (id, name, relation, primaryPhone, secondaryPhone) => {
  return axios
    .put(API_URL + "/" + id, {name: name, relation: relation, primaryPhone: primaryPhone, secondaryPhone: secondaryPhone},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const remove = (id) => {
  return axios
    .delete(API_URL + "/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const IceService = {
  all,
  get,
  create,
  update,
  remove
}

export default IceService;
