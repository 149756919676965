
const formatDateTimeFromMillis = (dateInMillis) => {
  if (!dateInMillis) return ''
  const date = new Date(dateInMillis)
  const dateTime = date.toISOString().split('T');

  return dateTime[0] + ' ' + dateTime[1].substring(0,5)
}

const formatDateFromMillis = (dateInMillis) => {
  if ( !dateInMillis) return ''
  const date = new Date(dateInMillis)
  const dateTime = date.toISOString().split('T');

  return dateTime[0]
}

const Utils = {
  formatDateTimeFromMillis,
  formatDateFromMillis,
}

export default Utils;
