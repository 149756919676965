import React, {useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AuthService from "../services/auth-service";
import {Backdrop} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    padding: '10px',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function LostPassword(_props) {
  const classes = useStyles();
  const form = useRef();

  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [backdrop, setBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setBackdrop(false);
  };

  const handleOpenBackdrop = () => {
    setBackdrop(true);
  };

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleLostPassword = (e) => {
    e.preventDefault();
    handleOpenBackdrop()
    setMessage("");

    AuthService.lostPassword(username).then(
      () => {
        setMessage("Vi har skickat instruktioner hur du återställer ditt lösenord till din email om vi hittade dig i vår databas!")
        handleCloseBackdrop()
      },
      (error) => {
        console.log(error)
        const resMessage = (
            error.response &&
            error.response.data &&
            error.response.data.error.message &&
            error.response.data.error.fields &&
            error.response.data.error.fields.username
          ) || (
            error.response &&
            error.response.data &&
            error.response.data.error.message &&
            error.response.data.error.fields &&
            error.response.data.error.fields.password
          ) || (
            error.response &&
            error.response.data &&
            error.response.data.error.message
          ) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        handleCloseBackdrop()
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={backdrop} onClick={handleCloseBackdrop}/>
      <CssBaseline/>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Ändra lösenord
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleLostPassword} ref={form}>
          <Typography component="p" variant="h7" className={classes.error} hidden={message === ""}>
            {message}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Användarnamn"
            label="Användarnamn"
            id="username"
            autoComplete="username"
            onChange={onChangeUsername}
            value={username}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLostPassword}
          >
            Ändra lösenord
          </Button>
        </form>
      </div>
    </Container>
  );
}
