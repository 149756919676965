import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Utils from '../../services/utils'
import {blueGrey} from "@material-ui/core/colors";
import authService from "../../services/auth-service";
import eventService from "../../services/event-service";
import EventTabs from "./EventTabs"
import {useSnackbar} from "notistack";
import ReservationService from "../../services/event-reservation-service";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import CancelEventDialog from "./CancelEventDialog";
import LeaderMenu from "./LeaderMenu";
import {cancelReason} from "./CancelationReason";
import UppdateEvent from "./UpdateEvent";
import EventService from "../../services/event-service";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: "center"
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: "800px"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  dialogWarning: {
    color: "rgb(255,255,255)",
    background: theme.palette.warning.main,
    padding: "8px 24px"
  },
  content: {
    minHeight: "400px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  bookButton: {
    background: "rgb(255,255,255)",
    '&:hover': {
      backgroundColor: blueGrey[100],
    }
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export default function EventDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {enqueueSnackbar} = useSnackbar()
  const [event, setEvent] = useState()
  const [booked, setBooked] = useState(false)
  const [bookable, setBookable] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const {id, open, handleClose } = props
  const [cancelDialog, setCancelDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [cancelationString, setCancelationString] = useState("")
  const [userId, setUserId] = useState(0)

  useEffect(() => {
    if (!id) {
      return
    }

    eventService.event(id).then(data => {
      const user = authService.getUserId()
      setUserId(user);
      setEvent(data);
      setBooked(isBooked(user, data))
      if (!!data.leader && data.status !== 'CANCELED') {
        setBookable(!isLeader(user, data))
        if(bookable === false){
          setCancelationString(" - Du kan inte boka/avboka du är ledare eller båtförare på detta evenemang")
        }
        if(bookable){
          setCancelationString("");
        }
      } else if (data.status === 'CANCELED') {
        setBookable(false)
        const reason = cancelReason(data.cancelationReason);
        setCancelationString(reason);
      }else {
          setBookable(false)
          setCancelationString('DYKLEDARE SAKNAS - ');
      }
    })
  }, [open, id, booked, bookable])

  // Only allow members to book, leader and boat masters is already booked by default
  const isLeader = (userId, leaderEvent) => {
    return (leaderEvent.leader?.id === userId || leaderEvent.boatMaster?.id === userId)
  }
  const hasBookings = (event) => {
    return event.bookings.length === 0;
  }

  const isBooked = (userId, bookedEvent) => {
    let bookedUser = bookedEvent.bookings.filter((booking) => {
      return booking.userId === userId
    })[0]
    return !!(bookedUser && bookedUser.status === "Bokad");
  }


  const handleDeleteEvent = () => {
    eventService.remove(id);
    enqueueSnackbar('Evenemanget är nu inställt', {variant: 'success'})
    handleClose()
  }
  const handleReservation = () => {
    setBookable(!isLeader(userId, event))
    if (booked) {
      ReservationService.cancel(id).then(() => {
        setBooked(false)
        enqueueSnackbar('Du är nu avbokad från evenemanget', {variant: 'success'})
      })
    } else {
      ReservationService.create(id).then(() => {
        setBooked(true)
        enqueueSnackbar('Du är nu bokad på evenemanget', {variant: 'success'})
      })
    }
  }

  const handleCancelDialog = () => {
    setCancelDialog(cancelDialog !== true);
  }

  const handleEditDialog =() => {
    setEditDialog(editDialog !== true);
  }

  const leaderMenu = () => {
    return (
    <LeaderMenu id={id} handleCancelDialog={handleCancelDialog} handleEditDialog={handleEditDialog} />
    )
  }

  const cancelEvent =  () => {
    return ( <CancelEventDialog cancelDialog={cancelDialog} eventID={id} handleCancelDialog={handleCancelDialog} />);
  }

  const handleUpdate = ({eventId, title, eventType, description, startDate, endDate, boatMaster, leader, diveSite}) => {
    console.log(eventId)
    EventService.update({eventId, title, eventType, description, startDate, endDate, boatMaster, leader, diveSite})
      .then(() => {
        enqueueSnackbar(title +  ' updaterat', {variant: 'success'})
      })
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleClose}
        className={classes.root}
        disableEnforceFocus
      >
        <DialogTitle className={bookable ? classes.dialogTitle : classes.dialogWarning}>{event.title} {cancelationString}</DialogTitle>
        <div className={classes.dialogInfo}>
          <Grid container spacing={2} className={classes.root} direction={"row"} justifyContent={"space-between"}>
            <Grid item md={4}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography>Start:</Typography>
                </Grid>
                <Grid item xs={9}><Typography>{Utils.formatDateTimeFromMillis(event?.startDate)}</Typography></Grid>
                <Grid item xs={3}><Typography>Slut:</Typography></Grid>
                <Grid item xs={9}><Typography>{Utils.formatDateTimeFromMillis(event?.endDate)}</Typography></Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography>Dykledare: {event.leader && (event?.leader?.firstname + ' ' + event?.leader?.lastname)}</Typography>
              <Typography>Båtförare: {event.boatMaster && (event.boatMaster.firstname + ' ' + event.boatMaster.lastname)}</Typography>
            </Grid>
            <Grid item md={2}>
              {authService.isLoggedIn() && <Button className={classes.bookButton} disabled={!bookable} onClick={handleReservation} size={"medium"}>
                {!booked
                ? 'Boka'
                : 'Avboka'}
              </Button>}
            </Grid>
          </Grid>
        </div>
        {authService.isLeader() || authService.isAdministrator() ? leaderMenu() : ''}
        <DialogContent className={classes.content}>
          <EventTabs description={event?.description} diveSite={event?.diveSite?.description} bookedUsers={event?.bookings}/>
        </DialogContent>
        <DialogActions>
          {hasBookings(event) &&
            <>
              <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ta bort</Button>
              <ConfirmDialog
                title="Ta bort evenemang?"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={handleDeleteEvent}
              >
                Är du säker på att du vill radera evenemanget?
              </ConfirmDialog>
            </>
          }
          <Button size={"medium"} color="secondary" onClick={handleClose}>Stäng</Button>
        </DialogActions>
        <UppdateEvent editDialog={editDialog}  handleEditDialog={handleEditDialog} id={id} handleUpdate={handleUpdate}/>
      </Dialog>
    )
  }

  return (
    <>
      {cancelDialog ? cancelEvent() : event && renderDialog()}
    </>
  );
}
