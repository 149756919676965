import React from 'react';
import './App.css';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from "react-router-dom";
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Header from './components/Header';
import Container from '@material-ui/core/Container'
import Navigator from './components/Navigator';
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import LostPassword from "./components/LostPassword";
import LostUsername from "./components/LostUsername";
import Dashboard from './containers/Dashboard'
import Events from './components/events/Events'
import Statistics from './containers/Statistics'
import Emails from "./containers/EmailContainer";
import Calendar from "./containers/Calendars";
import DiveSites from "./containers/DiveSiteContainer";
import MyPresentation from "./containers/MyPresentation";
import AdminMembers from "./containers/AdminMembers";
import AdminMembersBySectionAndYear from "./containers/AdminMembersBySection";
import AdminMemberSearch from "./containers/AdminMemberSearch";
import MyAccount from "./containers/MyAccount";
import AdminRoles from "./containers/AdminRoles";
import LinkContainer from "./containers/LinkContainer";
import {SnackbarProvider} from 'notistack';
import config from './config.json';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.msdk.se/">
        Malmö Sportdykarklubb
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      {' ' + config.version}
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    warning: {
      main: '#FF9063'
    }

  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('xs')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 230;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2, 0),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

const App = (props) => {
  const {classes} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <div className={classes.root}>
          <CssBaseline/>
          <nav className={classes.drawer}>
            <Hidden mdUp implementation="js">
              <Navigator
                PaperProps={{style: {width: drawerWidth}}}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden smDown implementation="css">
              <Navigator PaperProps={{style: {width: drawerWidth}}}/>
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header onDrawerToggle={handleDrawerToggle}/>
            <main className={classes.main}>
              <Container>
                <Switch>
                  <Route exact path="/login" component={Login}/>
                  <Route exact path="/lost-username" component={LostUsername}/>
                  <Route exact path="/lost-password" component={LostPassword}/>
                  <Route exact path="/reset-password/:token" component={ResetPassword}/>
                  <PrivateRoute exact path="/" component={Dashboard}/>
                  <PrivateRoute exact path="/links" component={LinkContainer}/>
                  <PrivateRoute exact path="/statistics" component={Statistics}/>
                  <PrivateRoute exact path="/events" component={Events}/>
                  <PrivateRoute exact path="/mail" component={Emails}/>
                  <PrivateRoute exact path="/calendar" component={Calendar}/>
                  <PrivateRoute exact path="/divesites" component={DiveSites}/>
                  <PrivateRoute exact path="/my-account" component={MyAccount}/>
                  <PrivateRoute exact path="/my-presentation" component={MyPresentation}/>
                  <PrivateRoute exact path="/admin/members" component={AdminMembers}/>
                  <PrivateRoute exact path="/admin/members-by-section" component={AdminMembersBySectionAndYear}/>
                  <PrivateRoute exact path="/admin/member/search" component={AdminMemberSearch}/>
                  <PrivateRoute exact path="/admin/roles" component={AdminRoles}/>
                </Switch>
              </Container>
            </main>
            <footer className={classes.footer}>
              <Copyright/>
            </footer>
          </div>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(App));
