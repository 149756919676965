import {makeStyles} from "@material-ui/core";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useState} from "react";

const useStyles = makeStyles((_theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1,
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '200px',
  },
  dialogContent: {
    height: "20vh"
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  inputField: {
    width: "49%"
  }
}));


export default function CreateInCaseOfEmergency(props) {
  const classes = useStyles();
  const {handleCreate, open, handleClose} = props;
  const theme = useTheme();
  const [name, setName] = useState("")
  const [relation, setRelation] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>Ny Kontakt</DialogTitle>
      <DialogContent className={classes.dialogContent}>

        <div className={classes.newRow}>
          <TextField
            id="name"
            onChange={e => setName(e.target.value)}
            required
            label="Namn"
            name="Namn"
            value={name}
            autoFocus
            className={classes.inputField}
          />
          <TextField
            id="relation"
            onChange={e => setRelation(e.target.value)}
            required
            label="Relation"
            name="Relation"
            value={relation}
            autoFocus
            className={classes.inputField}
          />
        </div>
        <div className={classes.newRow}>
          <TextField
            id="primaryPhone"
            onChange={e => setPrimaryPhone(e.target.value)}
            required
            label="Primär telefon"
            name="Primär telefon"
            value={primaryPhone}
            autoFocus
            className={classes.inputField}
          />
          <TextField
            id="secondaryPhone"
            onChange={e => setSecondaryPhone(e.target.value)}
            label="Sekundär Telefon"
            name="Sekundär Telefon"
            value={secondaryPhone}
            autoFocus
            className={classes.inputField}
          />
        </div>

      </DialogContent>
      <DialogActions>
        <Button size={"medium"} color="secondary" onClick={handleClose}>Avbryt</Button>
        <Button size={"medium"} color="primary" onClick={() => handleCreate(name, relation, primaryPhone, secondaryPhone )}>Spara</Button>
      </DialogActions>
    </Dialog>
  );

}
