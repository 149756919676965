import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/booking";

const create = (eventId) => {
  return axios
    .post(API_URL + "/" + eventId + "/create", null, {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
};

const cancel = (eventId) => {
  return axios
  .post(API_URL + "/" + eventId + "/cancel", null,{headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const ReservationService = {
  create,
  cancel
}

export default ReservationService
