import React, {useState} from 'react'
import memberService from "../../services/members-service";
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Loading from "../Loading";
import {
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemButton,
  TextField
} from "@mui/material";
import MemberDetailedDialog from "./MemberDetailedDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  queryField: {
    padding: theme.spacing(2),
  },
  scroll: {
    overflow: "scroll",
    height: "340px"
  }
}));

export default function Members() {

  const classes = useStyles()
  const [members, setMembers] = useState([])
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState([])

  const search = (name) => {
    setQuery(name)
    if (name.length > 2) {
      setLoading(true)
      memberService.search(name)
      .then(data => {
        setMembers(data)
        setLoading(false)
      })
    } else {
      setMembers([])
    }
  }

  const handleOnClick = (member) => {

    setSelectedMember(member)
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedMember([])
    setOpen(false)
  }

  return (
    <Paper className={classes.container}>
      <Typography className={classes.heading}>Sök efter tidigare och nuvarande medlemmar</Typography>
      <div className={classes.queryField}>
        <TextField className={classes.queryField} value={query} onChange={e => search(e.target.value)} fullWidth label="Sök på namn, minst tre tecken"/>
      </div>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            <Grid container>
              {members.map((row) => (
                  <ListItem key={row.id} disablePadding dense>
                    <ListItemButton id={row.id} onClick={() => handleOnClick(row)}>
                      <Grid item xs={8} md={4}>{row.name}</Grid>
                      <Hidden mdDown><Grid item md={5}><Link inputMode="email" href={"mailto:" + row.email}>{row.email}</Link></Grid></Hidden>
                      <Grid item xs={4} md={3}><Link inputMode="tel" href={"tel:" + row.phone}>{row.phone}</Link></Grid>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </Grid>
          </List>
        </Loading>
      </div>
      <MemberDetailedDialog
        memberId={selectedMember.id}
        open={open}
        handleClose={handleClose}/>
    </Paper>
  );
}
