import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {TextField} from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '700px'
  },
  dialogContent: {
    height: "50vh"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  rte: {
    height: "70%"
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export default function CreateDocument(props) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const {handleClose, handleCreate, open} = props
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>Nytt dokument</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          id="title"
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Titel"
          name="Title"
          value={title}
          autoFocus/>
        <ReactQuill className={classes.rte}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={description}
                    onChange={setDescription}/>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} color="secondary" onClick={handleClose}>Avbryt</Button>
        <Button size={"medium"} color="primary" onClick={() => handleCreate(title, description)}>Spara</Button>
      </DialogActions>
    </Dialog>
  );
}
