import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Utils from "../../services/utils";
import {Grid, List, ListItem, ListItemButton} from "@mui/material";
import Loading from "../Loading";
import MemberDetailedDialog from "./MemberDetailedDialog";
import MembersService from "../../services/members-service";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    height: "340px"
  }
}));

export default function Applicants() {

  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState([])

  useEffect(() => {
    setLoading(true)
    MembersService.applicants().then(data => {
      setApplicants(data);
      setLoading(false)
    })
  }, []);

  const handleOnClick = (member) => {
    setSelectedApplicant(member)
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedApplicant([])
    setOpen(false)
  }

  return (
    <Paper className={classes.container}>
      <Typography className={classes.heading}>Medlemsansökningar</Typography>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            <Grid container>
              {applicants.map((row, index) => (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton id={row.id} onClick={() => handleOnClick(row)}>
                      <Grid item xs={3}>{Utils.formatDateFromMillis(row.memberships[0].issued)}</Grid>
                      <Grid item xs={2}>{row.memberships[0].section}</Grid>
                      <Grid item xs={7}>{row.firstName} {row.lastName}</Grid>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </Grid>
          </List>
        </Loading>
      </div>
      <MemberDetailedDialog
        memberId={selectedApplicant.id}
        open={open}
        handleClose={handleClose}/>
    </Paper>
  );
}
