import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth-service";

const API_URL = process.env.REACT_APP_API_URL + "/api/";
const USER_ID = authService.getUserId();
const all = () => {
  return axios
    .get(API_URL + "certificates", {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const getUsers = (userId) => {
  return axios
    .get(API_URL + userId + "/certificates", {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const getById = (id) => {
  return axios
    .get(API_URL + "certificates/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const create = (organization, course, weight, description) => {
  return axios
    .post(API_URL + USER_ID + "/certificates/", {userId: USER_ID, organization: organization, course: course, weight: weight, description: description},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const update = (id, organization, course, weight, description) => {
  return axios
    .put(API_URL + USER_ID + "/certificates/" + id, {organization: organization, course: course, weight: weight, description: description},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const remove = (id) => {
  return axios
    .delete(API_URL + USER_ID + "/certificates/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}
const addExisting = (certificateId) => {
  console.log(API_URL + USER_ID )
  return axios
    .post(API_URL + USER_ID + "/certificates/" + certificateId, {id:certificateId}, {headers: authHeader()})
    .then ((response) => {
      return response.data;
    })
}


const CertificateService = {
  all,
  getUsers,
  getById,
  create,
  addExisting,
  update,
  remove
}

export default CertificateService;
