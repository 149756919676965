import React from 'react';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoatOutlined';
import EventIcon from '@mui/icons-material/EventOutlined';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {Button} from "@mui/material";

export default function PlainCalendarItem(props) {

  const {title, eventType, status, id, handleOpen} = props

  const setEventTypeIcon = (icon) => {
    switch (icon) {
      case 'CANCELED':
        return <CancelOutlinedIcon/>
      case 'TRIP':
      case 'trip':
        return <DirectionsBoatIcon/>
      case 'COURSE':
      case 'course':
        return <SchoolIcon/>
      case 'MEETING':
      case 'meeting':
        return <GroupsIcon/>
      default:
        return <EventIcon/>
    }
  }
  setEventTypeIcon(eventType);

  const setColor = (status) => {
    return "CANCELED" === status ? "error" : "info"
  }

  return (
    <Button style={{marginRight: "5px"}} color={setColor(status)} size="small" variant="outlined" startIcon={setEventTypeIcon(status === 'CANCELED' ? status : eventType)} onClick={() => handleOpen(id)}>
      {title.substring(0, 14)}
    </Button>
  );
}
