import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/chat";

const latest = () => {
  return axios
  .get(API_URL + "/latest", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const sendMessage = (message) => {
  const data = {"message": message}
  return axios
  .post(API_URL, data, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const DiveSiteService = {
  latest,
  sendMessage
}

export default DiveSiteService
