import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {blueGrey} from "@material-ui/core/colors";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import ReactQuill from "react-quill";
import DocumentService from "../../services/document-service";
import {TextField} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '700px'
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  bookButton: {
    background: "rgb(255,255,255)",
    '&:hover': {
      backgroundColor: blueGrey[100],
    }
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export default function Document(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {documentId, handleClose, handleDelete, handleEdit, open} = props
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (documentId) {
      DocumentService.get(documentId).then((data) => {
        setTitle(data.title)
        setDescription(data.description)
      })
    }
  }, [open, documentId])

  const handleSave = () => {
    setEditMode(false)
    handleEdit(documentId, title, description)
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        { editMode && <TextField
          id="title"
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Titel"
          name="Title"
          value={title}
          />}
        <ReactQuill theme={editMode ? "snow" : null} value={description} onChange={setDescription} readOnly={!editMode}/>
      </DialogContent>
      <DialogActions>
        {!editMode &&
          <>
            <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ta bort</Button>
            <ConfirmDialog
              title="Delete Post?"
              open={confirmDeleteOpen}
              setOpen={setConfirmDeleteOpen}
              onConfirm={handleDelete}
            >
              Är du säker på att du vill radera informationen?
            </ConfirmDialog>
            <Button size={"medium"} color="secondary" onClick={() => setEditMode(true)}>Redigera</Button>
            <Button size={"medium"} color="primary" onClick={handleClose}>Stäng</Button>
          </>
        }
        {editMode && <>
          <Button size={"medium"} color="secondary" onClick={handleClose}>Stäng</Button>
          <Button size={"medium"} color="primary" onClick={handleSave}>Spara</Button>
        </>
        }
      </DialogActions>
    </Dialog>
  );
}
