import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, List, ListItem, ListItemText, Paper} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import DocumentService from "../../services/document-service";
import Document from "./Document";
import CreateDocument from "./CreateDocument";
import Loading from "../Loading";
import {Add} from "@mui/icons-material";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
    color: theme.palette.text.secondary,
  },
  scroll: {
    overflow: "scroll",
    height: "385px"
  }
}));

export default function Documents() {

  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [create, setCreate] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = useState([]);
  const [document, setDocument] = useState([])

  const handleOpen = (openDocument) => {
    setDocument(openDocument)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCreate(false)
  }

  const handleCreate = (title, description) => {
    DocumentService.create(title, description)
    .then(() => {
      enqueueSnackbar(title + ' skapat', {variant: 'success'})
      load()
      setCreate(false)
    })
  }

  const handleEdit = (id, title, description) => {
    DocumentService.update(id, title, description)
    .then(() => {
      enqueueSnackbar(title + ' sparat', {variant: 'success'})
      load()
      setOpen(false)
    })
  }

  const load = () => {
    setLoading(true)
    DocumentService.all().then(newDocuments => {
      setData(newDocuments);
      setLoading(false)
    })
  }

  const handleDelete = () => {
    DocumentService.remove(document.id)
    .then(() => {
      enqueueSnackbar('Dokumentet är nu borttaget', {variant: 'success'})
      load()

    })
    setOpen(false);
  }

  useEffect(() => {
    load()
  }, []);

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item xs={10}>
          <Typography className={classes.heading}>Intern information</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.heading}>
            <Button onClick={setCreate}><Add/></Button>
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            {data.map((docItem) => (
                <ListItem key={docItem.id} button onClick={() => handleOpen(docItem)}>
                  <ListItemText primary={docItem.title}/>
                </ListItem>
              )
            )}
          </List>
        </Loading>
      </div>
      <Document documentId={document.id} handleEdit={handleEdit} handleDelete={handleDelete} handleClose={handleClose} open={open}/>
      <CreateDocument open={create} handleClose={handleClose} handleCreate={handleCreate}/>
    </Paper>
  );
}
