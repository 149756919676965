import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import UserBiographyService from "../../services/user-biography-service";
import {Button, Container, Paper, Typography} from "@mui/material";
import Loading from "../Loading";
import ReactQuill from "react-quill";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    maxHeight: "80vh"
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

}))


export default function Biography() {

  const classes = useStyles();
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [editMode, setEditMode] = useState(false);

  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const bubbleToolbar = {
    toolbar: []
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  const load = () => {
    setLoading(true);
    UserBiographyService.get()
      .then(newBio => {
        setDescription(newBio.description)
        setLoading(false);
      })
  }

  const handleClose = () => {
    setEditMode(false)
  }

  const handleSet = (text) => {
    UserBiographyService.create(text)
      .then(() => {
        enqueueSnackbar("Biografin är nu sparad", {variant: `success`})
        load()
        setEditMode(false)
      })
  }

  const handleDelete = () => {
    UserBiographyService.remove()
      .then(() => {
        enqueueSnackbar("Biografin är nu borttagen", {variant: `success`})
        load()
        setEditMode(false)
      })
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Paper sx={{overflow: 'hidden'}}>
      <Container>
        <div className={classes.topRow}>
          <Typography className={classes.heading}>Presentation</Typography>
        </div>
        <Loading loading={loading} />
        <ReactQuill
          className={classes.rte}
          modules={editMode ? modules : bubbleToolbar}
          formats={formats}
          theme={editMode ? "snow" : "bubble"}
          value={description || '' }
          onChange={setDescription}
          readOnly={!editMode}
        />
        {editMode &&
        <>
          <Button size={"medium"} color="primary" onClick={() => handleSet(description)}>Spara</Button>
          <Button size={"medium"} color="secondary" onClick={handleDelete}>Ta bort</Button>
          <Button size={"medium"} color="secondary" onClick={handleClose}>Avbryt</Button>
        </>
        }
        {!editMode && <>
          <Button size={"medium"} color="primary" onClick={() => setEditMode(true)}>Ändra</Button>
        </>
        }
      </Container>
</Paper>
  )
}
