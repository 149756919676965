import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@material-ui/core/Button";

const ConfirmDialog = (props) => {

  const { title, children, open, setOpen, onConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="secondary"
        >
          Nej
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
