import React, {useEffect, useState} from 'react'
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {AccountCircle, EmailRounded, Fingerprint, Home, LocalHospital, Phone, PriorityHigh} from "@mui/icons-material";
import Loading from "../Loading";
import MemberService from "../../services/member-service";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  scroll: {
    overflow: "scroll",
    height: "340px"
  },
  warning: {
    color: theme.palette.warning.main
  }
}));

export default function DetailedMember(_props) {

  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState([])

  useEffect(() => {
    setLoading(true)
    MemberService.myself()
      .then(data => {
        data.ssn = data.ssn.substring(0, 9)
        setUser(data)
        setLoading(false)
      })
  }, [])

  return (
    <Paper>
      <Typography className={classes.heading}>Dina uppgifter</Typography>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            <ListItem>
              <ListItemIcon><AccountCircle/></ListItemIcon>
              <ListItemText>{user.firstName} {user.lastName}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Phone/></ListItemIcon>
              <ListItemText>{user.phone}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><EmailRounded/></ListItemIcon>
              <ListItemText>{user.email}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Home/></ListItemIcon>
              <ListItemText>{user.address}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Home/></ListItemIcon>
              <ListItemText>{user.zipcode} {user.city}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Home/></ListItemIcon>
              <ListItemText>{user.country}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><Fingerprint/></ListItemIcon>
              <ListItemText className={user.validSsn ? '' : classes.warning}>{user.ssn}****</ListItemText>
            </ListItem>
            {user && user.inCaseOfEmergencies && user.inCaseOfEmergencies.length !== 0 ? user.inCaseOfEmergencies.map((ice) => (
              <ListItem key={ice.id}>
                <ListItemIcon><LocalHospital/></ListItemIcon>
                <ListItemText>{ice.name} {ice.phone}</ListItemText>
              </ListItem>
            )) : <ListItem>
              <ListItemIcon><PriorityHigh/></ListItemIcon>
              <ListItemText>Du bör lägga upp en kontakt vid olycka som vi kan nå. Gå till Kontouppgifter, du kan lägga till hur många du
                vill</ListItemText></ListItem>}
          </List>
        </Loading>
      </div>
    </Paper>
  )
}
