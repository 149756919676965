import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/divesite";

const all = () => {
  return axios
  .get(API_URL, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const get = (id) => {
  return axios
  .get(API_URL + "/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data
  })
}

const create = ({title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType}) => {
  return axios
    .post(API_URL, {name: title, description: description, level: level, type: diveType, requiresBoat: demandsBoat,
      maxDepth: maxDepth, position: { latitude: lat, longitude: long, geodeticSystem: mapType}},
      {headers: authHeader()})
      .then((response) => {
      return response.data;
    });
};

const update = ({id, title, description, level, maxDepth, lat, long, demandsBoat, mapType, diveType }) => {
  return axios
    .put(API_URL + "/" + id, {name: title, description: description, level: level, type: diveType, requiresBoat: demandsBoat,
      maxDepth: maxDepth, position: { latitude: lat, longitude: long, geodeticSystem: mapType}},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
};

const remove = (id) => {
  return axios
    .delete(API_URL + "/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
};
const DiveSiteService = {
  all,
  get,
  create,
  update,
  remove
}

export default DiveSiteService
