import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {blueGrey} from "@mui/material/colors";
import {
  makeStyles,
} from "@material-ui/core";
import DivesiteService from "../../services/divesite-service";
import ReactQuill from "react-quill";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "center",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: "700px"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  bookButton: {
    background: "rgb(255,255,255)",
    '&:hover': {
      backgroundColor: blueGrey[100],
    }
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  radioGroup: {
    padding: 5,
    margin: 5,
  },
}));

export default function DiveSite(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {diveSiteId, handleClose, handleDelete, handleEdit, open} = props
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [level, setLevel] = useState("");
  const [maxDepth, setMaxDepth] = useState("");
  const [requiresBoat, setRequiresBoat] = useState(false);
  const [diveType, setDiveType] = useState("");
  const [description, setDescription] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [mapType, setMapType] = useState("");


  useEffect(() => {
    if(diveSiteId) {
      DivesiteService.get(diveSiteId).then((data) => {
        setLevel(data.level);
        setMaxDepth(data.maxdepth);
        setName(data.name);
        setRequiresBoat(data.requiresBoat);
        setDiveType(data.type);
        setDescription(data.description);
        setLat(data.latitude);
        setLong(data.longitude);
        setMapType(data.geodeticSystem);

      })
    }
  }, [open, diveSiteId]);

  const handleDiveType = (event) => {
    setDiveType(event.target.value);
  };

  const handleDifficulty = (event) => {
    setLevel(event.target.value);
  };

  const handleMapType = (event) => {
    setMapType(event.target.value);
  }

  const handleDemandsBoat = (event) => {
    if(event.target.value === "on") {
      setRequiresBoat(true)
    } else {
      setRequiresBoat(false)
    }
  };

  const handleSave = () => {
    setEditMode(false)
    handleEdit({
      id: diveSiteId,
      name: name,
      description: description,
      level: level,
      maxDepth: maxDepth,
      lat: lat,
      long: long,
      requiresBoat: requiresBoat,
      mapType: mapType,
      diveType: diveType
    })
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>{name}</DialogTitle>
      <DialogContent>
        { editMode && <TextField
          id="title"
          onChange={(e => setName(e.target.value))}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Title"
          name="Title"
          value={name}
          />}
        {editMode &&
          <div className={classes.newRow}>
            <FormControl className={classes.radioGroup}>
              <FormLabel>Typ av dykning</FormLabel>
              <RadioGroup
                row
                aria-labelledby="diveTypeLabel"
                defaultValue={"Vrak"}
                name="row-diveType"
                value={diveType}
                onChange={handleDiveType}
              >
                <FormControlLabel value="Vrak" control={<Radio/>} label={"Vrak"}/>
                <FormControlLabel value="Natur" control={<Radio/>} label={"Natur"}/>
              </RadioGroup>
            </FormControl>

            <FormControl className={classes.radioGroup}>
              <FormLabel>Svårighetsgrad</FormLabel>
              <RadioGroup
                row
                aria-labelledby="diffucultyLabel"
                name="row-difficulty"
                value={level}
                onChange={handleDifficulty}
              >
                <FormControlLabel value="Lätt" control={<Radio/>} label={"Lätt"}/>
                <FormControlLabel value="Medel" control={<Radio/>} label={"Medel"}/>
                <FormControlLabel value="Svår" control={<Radio/>} label={"Svår"}/>
              </RadioGroup>
            </FormControl>
            <FormControl className={classes.radioGroup}>
              <FormLabel>Position (Kart/Sjökort-datum)</FormLabel>
              <RadioGroup
                row
                aria-labelledby="mapType"
                name="row-mapType"
                value={mapType}
                onChange={handleMapType}
              >
                <FormControlLabel value="WGS84" control={<Radio/>} label={"WGS84"}/>
                <FormControlLabel value="RT90" control={<Radio/>} label={"RT90"}/>
              </RadioGroup>
            </FormControl>
          </div>}
        {editMode &&
          <div className={classes.newRow}>
          <TextField
          id="lat"
          onChange={e => setLat(e.target.value)}
          required
          label="Lat"
          name="Lat"
          value={lat}
          autoFocus/>
          <TextField
          id="long"
          onChange={e => setLong(e.target.value)}
          required
          label="Long"
          name="Long"
          value={long}
          autoFocus/>
          <div className={classes.maxDepth}>
          <TextField
          id="maxDepth"
          onChange={e => setMaxDepth(e.target.value)}
          required
          label="Maxdjup i meter"
          name="Maxdepth"
          value={maxDepth}
          autoFocus/>
          </div>
          <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked onChange={handleDemandsBoat}/>} label="Kräver båt" />
          </FormGroup>
          </div>
        }
        <ReactQuill theme={editMode ? "snow" : null} value={description} onChange={setDescription} readOnly={!editMode}/>
      </DialogContent>
      <DialogActions>
        {!editMode &&
          <>
            <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ta bort</Button>
            <ConfirmDialog
              title="Delete Post?"
              open={confirmDeleteOpen}
              setOpen={setConfirmDeleteOpen}
              onConfirm={handleDelete}
            >
              Är du säker på att du vill radera informationen?
            </ConfirmDialog>
            <Button size={"medium"} color="secondary" onClick={() => setEditMode(true)}>Redigera</Button>
            <Button size={"medium"} color="primary" onClick={handleClose}>Stäng</Button>
          </>
        }
        {editMode && <>
          <Button size={"medium"} color="secondary" onClick={handleClose}>Stäng</Button>
          <Button size={"medium"} color="primary" onClick={handleSave}>Spara</Button>
        </>
        }
      </DialogActions>
    </Dialog>
  );
}
