import {Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import React, {useEffect, useState} from "react";
import memberService from "../../services/members-service";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {AddCircleOutlined, CheckCircleOutlineRounded} from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MemberDetailedDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {enqueueSnackbar} = useSnackbar()
  const {memberId, open, handleClose} = props

  const [tab, setTab] = useState(0)
  const [status, setStatus] = useState('')
  const [accountType, setAccountType] = useState('')
  const [userName, setUserName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [ssn, setSsn] = useState('')
  const [roles, setRoles] = useState([])
  const [memberships, setMemberships] = useState([])

  useEffect(() => {
    if (memberId === undefined) {
      return;
    }
    memberService.get(memberId).then(data => {
      setUserName(data.userName)
      setFirstName(data.firstName)
      setLastName(data.lastName)
      setAddress(data.address)
      setZipCode(data.zipcode)
      setCity(data.city)
      setCountry(data.country)
      setEmail(data.email)
      setPhone(data.phone)
      setSsn(data.ssn)
      setStatus(data.status)
      setAccountType(data.accountType)
      setRoles(data.roles)
      setMemberships(data.memberships)
    })
  }, [open, memberId])

  const handleAccountChange = (newAccountType) => {
    if (accountType != null) {
      memberService.changeAccount(memberId, newAccountType).then(() => {
          setAccountType(newAccountType);
          enqueueSnackbar('Kontot är nu ändrat till ' + newAccountType, {variant: 'success'})
        }
      )
    }
  };

  const handleStatusChange = (newStatus) => {
    if (status != null) {
      memberService.changeStatus(memberId, newStatus).then(() => {
        setStatus(newStatus);
        enqueueSnackbar('Kontot är nu ' + newStatus, {variant: 'success'})
      })
    }
  };

  const handleChangeTab = (tabId) => {
    setTab(tabId)
  }

  const handleSave = () => {
    enqueueSnackbar('Kontot är sparat!', {variant: 'success'})
  }

  const statusRender = (renderStatus) => {
    switch (renderStatus) {
      case 'PAID':
        return <Chip icon={<CheckCircleOutlineRounded/>} label="Betalt" variant="outlined" color={"success"}/>
      case 'NEW':
        return <Chip icon={<AddCircleOutlined/>} label="Ny medlem" variant="outlined" color={"primary"}/>
      default:
        return <Chip icon={<CheckCircleOutlineRounded/>} label="Obetald" variant="outlined" color={"error"}/>
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogTitle>{firstName} {lastName}</DialogTitle>
      <div>
        <Grid container direction="row" justifyContent="center" spacing="10px">
          <Grid item>
            <ToggleButtonGroup color="primary" value={accountType} exclusive onChange={handleAccountChange} size="small">
              <ToggleButton value="MEMBER">Medlem</ToggleButton>
              <ToggleButton value="LEADER">Ledare</ToggleButton>
              <ToggleButton value="ADMINISTRATOR">Administratör</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <ToggleButtonGroup color="primary" value={status} exclusive onChange={handleStatusChange} size="small">
              <ToggleButton value="ENABLED">Aktivt</ToggleButton>
              <ToggleButton value="DISABLED">Inaktivt</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </div>
      <DialogContent>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Medlem" {...a11yProps(0)}/>
          <Tab label="Medlemskap" {...a11yProps(1)}/>
          <Tab label="Roller" {...a11yProps(2)}/>
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Grid container spacing="10px">
            <Grid item xs={12}>
              <TextField id="userName" label="Användarnamn" variant="outlined" value={userName} disabled={true} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="firstName" label="Förnamn" variant="outlined" value={firstName} onChange={e => setFirstName(e.target.value)} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="lastName" label="Efternamn" variant="outlined" value={lastName} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="address" label="Adress" variant="outlined" value={address} fullWidth/>
            </Grid>
            <Grid item xs={5}>
              <TextField id="zipCode" label="Postnummer" variant="outlined" value={zipCode} fullWidth/>
            </Grid>
            <Grid item xs={7}>
              <TextField id="city" label="Stad" variant="outlined" value={city} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="country" label="Land" variant="outlined" value={country} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="phone" label="Telefon" variant="outlined" value={phone} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="email" label="eMail" variant="outlined" value={email} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="ssn" label="Personnummer" variant="outlined" value={ssn} fullWidth/>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Grid container spacing={"5px"}>
            {memberships.sort((a, b) => a.period - b.period).reverse().map((row) => (
                <>
                  <Grid item xs={2}>{row.period}</Grid>
                  <Grid item xs={2}>{row.section}</Grid>
                  <Grid item xs={6}>{row.name}</Grid>
                  <Grid item xs={2}>{statusRender(row.status)}</Grid>
                </>
              )
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Grid container spacing={"5px"}>
            {roles.map((row) => (
                <>
                  <Grid item xs={6}>{row.parent}</Grid>
                  <Grid item xs={6}>{row.name}</Grid>
                </>
              )
            )}
          </Grid>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color={"primary"}>Spara</Button>
        <Button onClick={handleClose} color={"secondary"}>Stäng</Button>
      </DialogActions>
    </Dialog>
  )
}
