import React from "react"
import {Typography} from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    padding: "20px 0 5px 0",
  }
}))

const EventInformation = () => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.title}>Båtutfärder</Typography>
      <Typography>
        Samling, om inget annat har angetts, är nere på klubben, vi försöker tänka på miljön och ekonomin genom att samåka, men även för att det finns begränsat
        med
        parkeringsplatser där vi lägger i båten.
        Vi gör oftast två dyk och vi är ute en hel dag så ta med dig vatten och matsäck. Vi kör sällan in till land om vi är ute på vrak.
        Du behöver ingen egen parkamrat, det fixar vi åt dig.
      </Typography>
      <Typography className={classes.title}>Medlemskap</Typography>
      <Typography>
        Bara medlemar får följa med på våra båtutfärder, dock kan du boka dig som gäst och betala på plats. Du kan även sätta in pengarna direkt på vårt
        BankGiro
        eller swish som du visar upp för dykledaren eftersom det kan ta några dagar innan vi kan registrera pengarna.
      </Typography>
      <Typography className={classes.title}>Loggbok och Certifikat</Typography>
      <Typography>
        Du bör ha med dig din loggbok och certifikat till utfärderna, dykledaren kan be dig styrka att du har rätt behörighet för dyket. Dykledaren har ansvaret
        för
        utfärden och har rätt att be dig avstå från dyk om han/hon anser att du inte har den utbildningen eller erfarenhet som krävs för dyket.
      </Typography>
      <Typography className={classes.title}>Luftfyllning</Typography>
      <Typography>
        På alla dyk gäller det att man har fyllda flaskor när man kommer till utfärderna, vi har inte möjlighet att fylla flaskor innan utfärden, det skulle ta
        för
        lång tid.
        Efter dyket så försöker vi fylla alla flaskor om det är möjligt.
      </Typography>
      <Typography className={classes.title}>Hyra av utrustning</Typography>
      <Typography>
        Vi har Flaskor, BCD och regulatorer (men inga dräkter) som våra medlemmar kan hyra när vi inte har någon kurs. Det brukar gå bra under sommaren men på
        vår och höst så är utrustningen som oftast upptagen i kursverksamheten.
      </Typography>
    </>
  )
}

export default EventInformation
