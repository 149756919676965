import React, {useEffect, useState} from 'react'
import memberService from "../../services/members-service";
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Loading from "../Loading";
import {Grid, Hidden, Link, List, ListItem, ListItemButton} from "@mui/material";
import MemberDetailedDialog from "./MemberDetailedDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    height: "340px"
  }
}));

export default function Members() {

  const classes = useStyles()
  const [json, setJson] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedApplicant, setSelectedApplicant] = useState([])

  useEffect(() => {
    setLoading(true)
    memberService.list()
    .then(data => {
      setJson(data)
      setLoading(false)
    })
  }, [])

  const handleOnClick = (member) => {
    setSelectedApplicant(member)
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedApplicant([])
    setOpen(false)
  }

  return (
    <Paper className={classes.container}>
      <Typography className={classes.heading}>Årets medlemmar ({json.length}) </Typography>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            <Grid container>
              {json.map((row, index) => (
                  <ListItem key={index} disablePadding dense>
                    <ListItemButton id={row.id} onClick={() => handleOnClick(row)}>
                      <Grid item xs={7} sm={4}>{row.name}</Grid>
                      <Hidden mdDown><Grid item sm={4}><Link inputMode="email" href={"mailto:" + row.email}>{row.email}</Link></Grid></Hidden>
                      <Hidden mdDown><Grid item xs={2} sm={2}><Link inputMode="tel" href={"tel:" + row.phone}>{row.phone}</Link></Grid></Hidden>
                      <Grid item xs={2}>{row.section}</Grid>
                      <Grid item xs={3}>{row.membership}</Grid>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </Grid>
          </List>
        </Loading>
      </div>
      <MemberDetailedDialog
        memberId={selectedApplicant.id}
        open={open}
        handleClose={handleClose}/>
    </Paper>
  );
}
