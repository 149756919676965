import React from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import DiveSites from "../components/divesites/DiveSites";
import MapForDiveSites from "../components/divesites/MapForDiveSites";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mapForDiveSites: {
   padding: theme.spacing(2),
    margin: theme.spacing(2),
  }

}));

export default function DiveSiteContainer() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="stretch">
        <Grid item xs={12} lg>
          <MapForDiveSites className={classes.mapForDiveSites}/>
          <DiveSites/>
        </Grid>
      </Grid>
    </div>
  );
}
