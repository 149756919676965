import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/mail";

const all = () => {
  return axios
  .get(API_URL, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const create = (subject, body, toSections, toGroups) => {
  return axios
  .post(API_URL + "/newsletter", {subject: subject, body: body, toSections: toSections, toGroups: toGroups},{headers: authHeader()})
  .then((response) => {
    return response.data;
  })
};

const get = (id) => {
  return axios
  .get(API_URL + "/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const allTemplates = () => {
  return axios
  .get(API_URL + "/template", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const getTemplate = (id) => {
  return axios
  .get(API_URL + "/template/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const createTemplate = (title, subject, languageCode, content) => {
  return axios
  .post(API_URL + "/template", {name: title, subject: subject, languageCode: languageCode, content: content},{headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const updateTemplate = (id, title, subject, languageCode, content) => {
  return axios
  .put(API_URL + "/template/" + id, {name: title, subject: subject, languageCode: languageCode, content: content},{headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const deleteTemplate = (id) => {
  return axios
  .delete(API_URL + "/template/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const MailService = {
  all,
  create,
  get,
  allTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate
}

export default MailService
