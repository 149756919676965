import React, {useEffect, useState} from 'react'
import LinkService from '../../services/link-service'
import {Hidden, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2)
  },
}));

export default function Links() {
  const classes = useStyles()
  const [data, setData] = useState([]);

  useEffect(() => {
    LinkService.getAll().then(links => {
      setData(links);
    })
  }, []);

  const handleOpenUrl = (link) => {
    window.open(link.url, '_blank').focus()
  }

  return (
    <Paper sx={{width: '100%', overflow: 'hidden'}}>
      <Typography className={classes.heading}>Länkar</Typography>
      <TableContainer component={Paper} sx={{maxHeight: "80vh"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Grupp</TableCell>
              <TableCell>Namn</TableCell>
              <Hidden smDown><TableCell>Beskrivning</TableCell></Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((link) => (
                <TableRow hover key={link.id} onClick={() => handleOpenUrl(link)}>
                  <TableCell component="th" scope="row">
                    {link.category}
                  </TableCell>
                  <TableCell>{link.title}</TableCell>
                  <Hidden smDown><TableCell>{link.description}</TableCell></Hidden>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
