import {makeStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, useMediaQuery, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import CertificateService from "../../services/certificate-service";
import CreateCertificate from "./CreateCertificate";
import AuthService from "../../services/auth-service";

const useStyles = makeStyles((_theme) => ({

}))
export default function AddCertificate (props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState([]);
  const {open, handleClose, handleCreate, handleAddExisting } = props;
  const [createNewCert, setCreateNewCert] = useState(false);

  const [id, setId] = useState(0);

  useEffect(() => {
    CertificateService.all().then(certs => {
      setData(certs);
    })
  }, [])

  const handleCertificate = (event) => {
    setId(event.target.value);
  }

  const handleCloseCreateNew = () => {
    setCreateNewCert(false);
  }
  return (
     <Dialog open={open}
            fullScreen={fullScreen}
            maxWidth={"md"}
            fullWidth={true}
            onClose={handleClose}
            className={classes.root}
            >
      <DialogTitle className={classes.dialogTitle}>Nytt certifikat</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.newRow}>
          <InputLabel id="weightList">Välj kurs</InputLabel>
        </div>
        <div className={classes.newRow}>
          <Select
            labelId="weightSelectLabel"
            id="weightSelect"
            value={id}
            label="Motsvarar"
            fullWidth={true}
            defaultValue=''
            onChange={handleCertificate}
          >
            {data.map((certificate) => (
              <MenuItem
                value={certificate.id}
                key={certificate.id}
              >{certificate.course}</MenuItem>
            ))}

          </Select>
        </div>
        {AuthService.isAdministrator() ? (
          <div className={classes.newRow}>
            <InputLabel id="weightList">Hittar du inte certifikatet i listan?</InputLabel>
            <Button size={"medium"} color="info" onClick={setCreateNewCert}>Skapa nytt</Button>
          </div>
        ): null}

      </DialogContent>
      <DialogActions>
        <Button size={"medium"} color="secondary" onClick={handleClose}>Avbryt</Button>
        <Button size={"medium"} color="primary" onClick={() => handleAddExisting(id)}>Lägg Till</Button>
      </DialogActions>
       <CreateCertificate createNewCert={createNewCert}  handleCreate={handleCreate} handleCloseCreateNew={handleCloseCreateNew} />
    </Dialog>
  )

}
