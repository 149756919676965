import * as React from 'react';
import {Component} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

export default class SectionSelection extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChange((event.target).value)
  }

  render() {
    return (
        <FormControl>
          <RadioGroup
              row
              aria-labelledby="section-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={this.props.section}
              onChange={this.handleChange}
          >
            <FormControlLabel value="1" control={<Radio/>}
                              label="Dyksektionen"/>
            <FormControlLabel value="2" control={<Radio/>}
                              label="Fridykarsektionen"/>
            <FormControlLabel value="3" control={<Radio/>}
                              label="Simsektionen"/>
            <FormControlLabel value="4" control={<Radio/>} label="Supporter"/>
          </RadioGroup>
        </FormControl>
    )
  }
}
