import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import emailService from "../../services/email-service";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(_theme => ({
  content: {
    height: "620px"
  },
  rte: {
    width: "max-content",
    height: "360px"
  },
  center: {
    flex: "1",
    alignItems: "center",
    textAlign: "center"
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function SimpleDialog(props) {
  const {loading} = props;
  const classes = useStyles()

  return (
      <Dialog open={loading}>
        <DialogTitle>Sending email</DialogTitle>
        <DialogContent className={classes.center}>
          <CircularProgress/>
        </DialogContent>
      </Dialog>
  );
}

export default function EmailDetailDialog(props) {

  const classes = useStyles()
  const {emailId, open, handleClose, createMode = false, handleCreate} = props
  const [loading, setLoading] = useState(false)
  const [emailSubject, setEmailSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [emailToGroups, setEmailToGroups] = React.useState([]);
  const [emailToSections, setEmailToSections] = React.useState([]);
  const groups = {
    "Dykning": [{"id": 7, "name": "Sektionsordförande"},
      {"id": 45, "name": "Båtansvarig"}, {"id": 59, "name": "Båtföraraspirant"},
      {"id": 22, "name": "Båtförare"}, {"id": 43, "name": "Dykledare"},
      {"id": 55, "name": "Eventsamordnare"},
      {"id": 47, "name": "Gasblender Nitrox"},
      {"id": 53, "name": "Gasblender Trimix"},
      {"id": 51, "name": "Instruktör *"}, {"id": 15, "name": "Instruktör **"},
      {"id": 58, "name": "Instruktör ***"},
      {"id": 44, "name": "Kompressoransvarig"},
      {"id": 21, "name": "Kompressorskötare"},
      {"id": 56, "name": "UV-Foto ansvarig"},
      {"id": 23, "name": "Utbildningsansvarig"}],
    "Övrigt": [{"id": 52, "name": "HLR-instruktör, HLR-grupp"},
      {"id": 37, "name": "Kon Tiki"}, {"id": 40, "name": "Krishanteringsgrupp"},
      {"id": 61, "name": "Material Ansvarig"},
      {"id": 54, "name": "SISU Ansvarig"},
      {"id": 46, "name": "Sekreta utskottet"},
      {"id": 39, "name": "Säkerhetsansvarig"},
      {"id": 38, "name": "Valberedning"}],
    "Fridykning": [{"id": 9, "name": "Sektionsordförande"},
      {"id": 13, "name": "Ledare"}],
    "Styrelse": [{"id": 6, "name": "Ordförande"},
      {"id": 24, "name": "Vice ordförande"}, {"id": 25, "name": "Kassör"},
      {"id": 27, "name": "Sekreterare"},
      {"id": 26, "name": "Styrelseledarmot"}, {"id": 28, "name": "Suppleant"},
      {"id": 32, "name": "Internrevisor"},
      {"id": 34, "name": "Internrevisor, suppleant"}],
    "IT": [{"id": 50, "name": "IT Ansvarig"},
      {"id": 60, "name": "Social media-ansvarig"}]
  }

  useEffect(() => {
    setLoading(false)
    if (!createMode && open) {
      emailService.get(emailId).then(email => {
        setEmailSubject(email.subject)
        setEmailBody(email.body)
      })
    } else {
      setEmailSubject('')
      setEmailBody('')
      setEmailToSections([])
      setEmailToGroups([])
    }
  }, [open, createMode, emailId])

  const handleSave = () => {
    setLoading(true)
    handleCreate(emailSubject, emailBody, emailToSections, emailToGroups,
        setLoading)
  }

  const handleChangeSections = (event) => {
    const {
      target: {value},
    } = event;
    setEmailToSections(// On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,);
  };

  const handleChangeGroups = (event) => {
    const {
      target: {value},
    } = event;
    setEmailToGroups(// On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,);
  };

  return (
      <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={open}
          onClose={handleClose}
      >
        <DialogTitle>{emailSubject}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content}>
            <SimpleDialog
                loading={loading}
                onClose={handleClose}
            />

            <Grid container spacing={2}>
              {createMode &&
                  <Grid item xs={12}>
                    <TextField
                        id="subject"
                        onChange={(e) => setEmailSubject(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Titel"
                        name="Subject"
                        value={emailSubject}
                    />
                  </Grid>
              }
              {createMode && <>
                <Grid item xs={6}>
                  <InputLabel htmlFor="section-email-to-select">Skicka mail till
                    en eller flera sektioner</InputLabel>
                  <Select multiple
                          labelId="section-email-to-select"
                          fullWidth
                          value={emailToSections}
                          onChange={handleChangeSections}
                          input={<OutlinedInput label="Tag"/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                  >
                    <MenuItem value="Dyk">
                      <Checkbox checked={emailToSections.indexOf("Dyk") > -1}/>
                      <ListItemText primary="Dyk"/>
                    </MenuItem>
                    <MenuItem value="Fridyk"> <Checkbox
                        checked={emailToSections.indexOf("Fridyk") > -1}/>
                      <ListItemText primary="Fridyk"/>
                    </MenuItem>
                    <MenuItem value="Sim">
                      <Checkbox checked={emailToSections.indexOf("Sim") > -1}/>
                      <ListItemText primary="Sim"/>
                    </MenuItem>
                    <MenuItem value="Supporter">
                      <Checkbox
                          checked={emailToSections.indexOf("Supporter") > -1}/>
                      <ListItemText primary="Supporter"/>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="grouped-email-to-select">Skicka mail till
                    en eller flera grupper</InputLabel>
                  <Select multiple
                          labelId="grouped-email-to-select"
                          fullWidth
                          value={emailToGroups}
                          onChange={handleChangeGroups}
                          input={<OutlinedInput label="Tag"/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                  >
                    {Object.keys(groups).map(groupKey => (
                        groups[groupKey].map(item => (
                            <MenuItem value={item.name}>
                              <Checkbox
                                  checked={emailToGroups.indexOf(item.name)
                                      > -1}/>
                              <ListItemText primary={item.name}/>
                            </MenuItem>
                        ))
                    ))}
                  </Select>
                </Grid>
              </>
              }
              <Grid item xs={12}>
                <ReactQuill
                    theme={createMode ? "snow" : null}
                    value={emailBody} onChange={setEmailBody}
                    readOnly={!createMode}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!createMode && <Button onClick={handleClose}>Close</Button>}
          {createMode && <>
            <Button size={"medium"} color="secondary"
                    onClick={handleClose}>Stäng</Button>
            <Button size={"medium"} color="primary"
                    onClick={handleSave}>Spara</Button>
          </>}
        </DialogActions>
      </Dialog>
  )
}
