import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import PlainCalendarView from "../components/calendar/PlainCalendarView";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

}));

export default function Calendars() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PlainCalendarView/>
    </div>
  );
}
