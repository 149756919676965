import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import CertificateService from "../../services/certificate-service";
import authService from "../../services/auth-service";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../Loading";
import Certificate from "./Certificate";
import AddCertificate from "./AddCertificate";

const useStyles = makeStyles((theme) => ({
   root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    maxHeight: "80vh"
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}))
export default function Certificates() {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const userId = authService.getUserId();

  const handleOpen = (certificate) => {
    CertificateService.getById(certificate.id).then(fetchedData => {
      setCertificate(fetchedData)
      setOpen(true);
    })
  }

  const handleClose = () => {
    setOpen(false);
    setCreate(false);
  }

  const handleCreate = (organization, course, description, weight) => {
    CertificateService.create(organization, course, weight, description)
      .then(() => {
        enqueueSnackbar(course + " sparat", {variant: "success"});
        load();
        setCreate(false);
      })
  }

  const handleAddExisting = (certificateId) => {
    CertificateService.addExisting(certificateId)
      .then(() => {
        enqueueSnackbar( certificate.course + " sparat", {variant: "success"});
        load();
        setCreate(false);
      })
  }

  const handleEdit = (id, organization, course, weight, description) => {
    CertificateService.update(id, organization, course, weight, description)
      .then(() => {
        enqueueSnackbar(course + " uppdaterat", {variant: "success"});
        load();
      })
  }

  const handleDelete = (id) => {
    console.log("ID: " + id)
    CertificateService.remove(id)
      .then(() => {
        enqueueSnackbar("Certifikatet är nu borttaget", {variant: "success"});
        load();
        setOpen(false);
      })
  }
  const load = () => {
    setLoading(true)
    CertificateService.getUsers(userId).then(newCertificates => {
      setData(newCertificates)
      setLoading(false)
    })
  }

  useEffect(() => {
    load()
  }, []);

  return(
    <Paper sx={{overflow: 'hidden'}}>
      <div className={classes.topRow}>
        <Typography className={classes.heading}>Dykutbildningar</Typography>
        <Button size={"large"} variant="text" onClick={setCreate}> <AddIcon /> </Button>
      </div>
      <Loading loading={loading}/>
      <TableContainer component={Paper} sx={{maxHeight: "80vh"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Organisation</TableCell>
              <TableCell>Kurs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((certificate) => (
              <TableRow hover key={certificate.id} onClick={() => handleOpen(certificate)}>
                <TableCell>{certificate.organization}</TableCell>
                <TableCell>{certificate.course}</TableCell>
              </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Certificate certificateId={certificate.id} handleClose={handleClose} handleEdit={handleEdit} handleDelete={handleDelete} open={open} />
      <AddCertificate open={create} handleClose={handleClose} handleCreate={handleCreate} handleAddExisting={handleAddExisting}  />
    </Paper>
  );
}
