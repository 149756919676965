import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import BookingService from "../../services/booking-service";
import {List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import Loading from "../Loading";
import EventDialog from "../events/EventDialog";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import Utils from "../../services/utils";


const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
    color: theme.palette.text.secondary,
  },
  scroll: {
    overflow: "scroll",
    height: "auto"
  }
}))
export default function Bookings() {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventDialog, setEventDialog] = useState([]);

  const handleOpen = (e) => {
    setEventDialog(e)
    setOpen(true)
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true)
    BookingService.getUsersUpcommingBookings().then(data => {
      setEvents(data);
      setLoading(false)
    })
  }, []);

  const setEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 'TRIP':
      case 'trip':
        return <DirectionsBoatIcon/>
      case 'COURSE':
      case 'course':
        return <SchoolIcon/>
      case 'MEETING':
      case 'meeting':
        return <GroupsIcon/>
      default:
        return <EventIcon/>
    }
  }

    return (
      <Paper className={classes.container}>
        <Typography className={classes.heading}>Bokade kurser/utfärder/evenemang</Typography>
        <div className={classes.scroll}>
          <Loading loading={loading}>
            <List dense>
              {events ? events.map((event) => (
                <ListItem key={event.id} onClick={() => handleOpen(event)}>
                  <ListItemIcon>{setEventTypeIcon(event.eventType)}</ListItemIcon>
                  <ListItemText>{Utils.formatDateFromMillis(event.startDate)}&nbsp;&nbsp;{event.title}</ListItemText>
                </ListItem>
              )) : ''
              }
            </List>
          </Loading>
        </div>
        <EventDialog id={eventDialog.id} open={open} handleClose={handleClose}/>
      </Paper>
    );
}
