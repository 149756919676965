import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/event";

const upcoming = () => {
  return axios
    .get(API_URL + "/upcoming")
    .then((response) => {
      return response.data;
    });
};

const promoted = () => {
  return axios
    .get(API_URL + "/promote")
    .then((response) => {
      console.log(response.data)
      return response.data;
    });
};

const event = (id) => {
  return axios
    .get(API_URL + "/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
};

const create = ({title, type, eventType, description, startDate, endDate, boatMaster, leader, diveSite}) => {
  return axios
    .post(API_URL, {title: title, type: "EVENT", eventType: eventType, description: description,
      startDate: startDate, endDate: endDate, boatMaster: boatMaster, leader: leader, diveSite: diveSite},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const update = ({eventId, title, eventType, description, startDate, endDate, boatMaster, leader, diveSite}) => {
  return axios
    .put(API_URL + "/" + eventId, {title: title, type: "EVENT", eventType: eventType, description: description,
      startDate: startDate, endDate: endDate, boatMaster: boatMaster, leader: leader, diveSite: diveSite},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const cancel = (id, message, reason) => {
  return axios
    .post(API_URL + "/cancel/" + id, {message, reason}, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const remove = (id) => {
  return axios
    .delete(API_URL + "/" + id, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}
const eventDuringMonth = (year, month) => {
  return axios
    .get(API_URL + `/year/${year}/month/${month}`)
    .then((response) => {
      return response.data;
    });
};

const downloadIce = (id) => {
  return axios
    .get(API_URL + "/" +  id + "/ice", {headers: authHeader(), responseType: "blob"})
    .then((response) => {
      openPDF(id + "-ice.pdf", response)
    });
};

const downloadDiveProtocol = (id) => {
  return axios
    .get(API_URL + "/" + id + "/dive-protocol", {headers: authHeader(), responseType: "blob"})
    .then((response) => {
      openPDF(id + "-dykprotokol.pdf", response)
    });
};

const downloadAccounting = (id) => {
  return axios
    .get(API_URL + "/" + id + "/accounting", {headers: authHeader(), responseType: "blob"})
    .then((response) => {
      openPDF(id + "-redovisning.pdf", response)
    });
};

const openPDF = (filename, response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

const EventService = {
  upcoming,
  promoted,
  event,
  create,
  update,
  remove,
  cancel,
  eventDuringMonth,
  downloadIce,
  downloadAccounting,
  downloadDiveProtocol
}

export default EventService
