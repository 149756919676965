import React from 'react'
import Grid from '@mui/material/Grid';
import Members from "../components/members/MembersBySectionAndYear"

export default function AdminMembers() {

  return (
    <>
      <Grid container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
      >
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Members/>
        </Grid>
      </Grid>
    </>
  );
}
