import React from 'react'
import Events from "../components/events/Events";
import Documents from "../components/documents/Documents";
import Chat from "../components/chat/Chat";
import DetailedMember from "../components/members/DetailedMember";
import {Grid} from "@mui/material";
import PromotedEvents from "../components/events/PromotedEvents";

const Dashboard = () => {

  return (
    <Grid container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
    >
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <PromotedEvents />
      </Grid>
      <Grid item xs={12} md={12} lg={5} xl={5}>
        <DetailedMember/>
      </Grid>
      <Grid item xs={12} md={12} lg={7} xl={7}>
        <Events/>
      </Grid>
      <Grid item xs={12} md={12} lg={5} xl={5}>
        <Documents/>
      </Grid>
      <Grid item xs={12} md={12} lg={7} xl={7}>
        <Chat/>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
