import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, InputLabel,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import CertificateService from "../../services/certificate-service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '700px'
  },
  dialogContent: {
    height: "50vh"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  rte: {
    height: "70%"
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  radioGroup: {
    padding: 5,
    margin: 5,
  },

  maxDepth: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  }
}))
export default function CreateCertificate(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {handleCloseCreateNew, handleCreate, createNewCert} = props;
  const [course, setCourse] = useState("");
  const [organization, setOrganization] = useState("");
  const [weight, setWeight] = useState(0);
  const [description, setDescription] = useState("");
  const [data, setData] = useState([]);


  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  useEffect(() => {
    CertificateService.all().then(allCerts => {
      setData(allCerts)
    })
  }, [])

  const handleWeight = (event) => {
    setWeight(event.target.value)
  }


  return (
    <Dialog open={createNewCert}
            fullScreen={fullScreen}
            maxWidth={"md"}
            fullWidth={true}
            onClose={handleCloseCreateNew}
            className={classes.root}
            >
      <DialogTitle className={classes.dialogTitle}>Nytt certifikat</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField id="organization"
                   onChange={(e) => setOrganization(e.target.value)}
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   label="Organization"
                   name="Organization"
                   value={organization}
                   autoFocus
        />
        <div className={classes.newRow}>
           <TextField id="Course"
                   onChange={(e) => setCourse(e.target.value)}
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   label="Course"
                   name="Course"
                   value={course}
                   autoFocus
        />
        </div>
        <div className={classes.newRow}>
          <InputLabel id="weightList">Motsvarar kurs</InputLabel>
        </div>
        <div className={classes.newRow}>

          <Select
            labelId="weightSelectLabel"
            id="weightSelect"
            value={weight}
            label="Motsvarar"
            fullWidth
            onChange={handleWeight}
          >
            {data.map((certificate) => (
              <MenuItem key={certificate.id} value={certificate.weight}>{certificate.course}</MenuItem>
            ))}
          </Select>
        </div>
      <ReactQuill className={classes.rte}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  value={description}
                  onChange={setDescription}
      />
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} color="secondary" onClick={handleCloseCreateNew}>Avbryt</Button>
        <Button size={"medium"} color="primary" onClick={() => handleCreate(organization, course, description, weight)}>Spara</Button>
      </DialogActions>
    </Dialog>
  )

}
