import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/documents";

const all = () => {
  return axios
  .get(API_URL, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const get = (id) => {
  return axios
  .get(API_URL + "/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const create = (title, description) => {
  return axios
  .post(API_URL, {title: title, description: description}, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const update = (id, title, description) => {
  return axios
  .put(API_URL + "/" + id, {title: title, description: description}, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const remove = (id) => {
  return axios
  .delete(API_URL + "/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const DocumentService = {
  all,
  get,
  create,
  update,
  remove
}

export default DocumentService
