import React from 'react'
import Grid from '@mui/material/Grid';
import Applicants from "../components/members/Applicants";
import Members from "../components/members/Members"
import UnpaidMembershipFees from "../components/members/UnpaidMembershipFees";

export default function AdminMembers() {

  return (
    <>
      <Grid container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
      >
        <Grid item xs={12} md={12} lg={5} xl={5}>
          <Applicants/>
        </Grid>
        <Grid item xs={12} md={12} lg={7} xl={7}>
          <UnpaidMembershipFees/>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Members/>
        </Grid>
      </Grid>
    </>
  );
}
