import React from "react"
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
  root: {
    textAlign: "center",
  },
}))

const Loading = (props) => {
  const classes = useStyles()
  const {loading} = props

  const loadingScreen = () => {
    return (
      <div className={classes.root}>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <>
      {loading ? loadingScreen() : props.children}
    </>
  )
}

export default Loading
