import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import emailService from "../../services/email-service";
import Typography from "@material-ui/core/Typography";
import {Button, Grid, List, ListItem, ListItemButton, Paper} from "@mui/material";
import EmailTemplateDialog from "./EmailTemplateDetailDialog"
import {Add} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    height: "80vh"
  }
}));

export default function EmailTemplates() {

  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const [data, setData] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [createMode, setCreateMode] = useState(false);

  useEffect(() => {
    emailService.allTemplates().then(allTemplates => {
      setData(allTemplates);
    })
  }, [open]);

  const handleOpen = (template) => {
    setSelectedTemplateId(template.externalId)
    setOpen(true)
    setCreateMode(false)
  }

  const handleClose = () => {
    setSelectedTemplateId(null)
    setOpen(false)
    setCreateMode(false)
  }

  const handleCreate = () => {
    setSelectedTemplateId(null)
    setCreateMode(true)
    setOpen(true)
  }

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item xs={10}>
          <Typography className={classes.heading}>Mail Mallar</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.heading}>
            <Button onClick={handleCreate}><Add/></Button>
          </Typography>
        </Grid>
      </Grid>
      <List dense>
        <Grid container>
          {data.map((row) => (
              <ListItem id={row.id} disablePadding>
                <ListItemButton onClick={() => handleOpen(row)}>
                  <Grid item xs={4} md={4}>{row.name}</Grid>
                  <Grid item xs={1} md={1}>{row.languageCode}</Grid>
                  <Grid item xs={7} md={7}>{row.subject}</Grid>
                </ListItemButton>
              </ListItem>
            )
          )}
        </Grid>
      </List>
      <EmailTemplateDialog templateId={selectedTemplateId} open={open} create={createMode} handleClose={handleClose}/>
    </Paper>
  );
}
