export const cancelReason = (data) => {
  switch (data){
    case 'WEATHER':
      return ' - INSTÄLLT pga dåligt väder';
    case 'TO_FEW_PARTICIPANTS':
      return ' - INSTÄLLT pga för få deltagare - ';
    case 'SICKNES':
      return ' - INSTÄLLT pga sjukdom ';
    case 'BOAT':
      return ' - INSTÄLLT pga ingen tillgänglig båt - ';
    default:
      return "";
  }
}
