import * as React from 'react';
import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import EventService from "../../services/event-service";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PlainCalendarItem from "./PlainCalendarItem";
import {Button, Grid} from "@mui/material";
import EventDialog from "../events/EventDialog";
import CreateEvent from "../events/CreateEvent";
import AddIcon from "@mui/icons-material/Add";
import authService from "../../services/auth-service";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 0, 0, 2),
  },
  headerRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  container: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "50%",
    color: 'rgba(255, 255, 255, 0.7)',
  },
  day: {
    height: "3rem",
    display: "flex",
    padding: theme.spacing(1, 2, 1, 2),
    borderBottom: "solid",
    borderBottomColor: "gainsboro",
    borderWidth: ".5px",
  },
  date: {
    width: "30px",
  },
  weekday: {
    width: "40px",
  },
  eventLink: {
    padding: theme.spacing(1)
  }
}));

const nameOfMonth = (month) => {
  switch (month) {
    case 0:
      return 'Januari';
    case 1:
      return 'Februari';
    case 2:
      return 'Mars';
    case 3:
      return 'April';
    case 4:
      return 'Maj';
    case 5:
      return 'Juni';
    case 6:
      return 'Juli';
    case 7:
      return 'Augusti';
    case 8:
      return 'September';
    case 9:
      return 'Oktober';
    case 10:
      return 'November';
    case 11:
      return "December";
    default:
      return month;
  }
}

export default function PlainCalendarView() {

  const classes = useStyles();
  const date = new Date();
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [data, setData] = useState([]);
  const [currentEventId, setCurrentEventId] = useState(null)
  const [open, setOpen] = useState(false)
  const [create, setCreate] = useState(false);
  const isUserLeader  = authService.isLeader();
  const isAdministrator = authService.isAdministrator();
  const {enqueueSnackbar} = useSnackbar();

  const handleOpen = (id) => {
    setCurrentEventId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCreate(false)
    get(year, month);
  }

  const handleCreate = ({title, type, eventType, description, startDate, endDate, boatMaster, leader, diveSite}) => {
    EventService.create({title, type, eventType, description, startDate, endDate, boatMaster, leader, diveSite})
      .then(() => {
        enqueueSnackbar(title +  ' sparat', {variant: 'success'})
        setCreate(false)
        get(year, month);
      })
  };


  const get = (currentYear, currentMonth) => {
    EventService.eventDuringMonth(currentYear, currentMonth + 1).then(newData => {
      const days = getDaysInMonth(currentYear, currentMonth);
      newData.forEach(event => {
        let currentDate = new Date(event.startDate).getDate()
        days[currentDate - 1].events.push(event)
      })
      setData(days)
    })
  }

  useEffect(() => {
    get(year, month)
  }, [month, year, open])

  const weekday = (id) => {
    let dayOfWeek;
    switch (id) {
      case 0:
        dayOfWeek = "Söndag"
        break;
      case 1:
        dayOfWeek = "Måndag"
        break;
      case 2:
        dayOfWeek = "Tisdag"
        break;
      case 3:
        dayOfWeek = "Onsdag"
        break;
      case 4:
        dayOfWeek = "Torsdag"
        break;
      case 5:
        dayOfWeek = "Fredag"
        break;
      case 6:
        dayOfWeek = "Lördag"
        break;
      default:
        dayOfWeek = "Error"
    }
    return dayOfWeek
  }

  const getDaysInMonth = (selectedYear, selectedMonth) => {
    let selectedDate = new Date(selectedYear, selectedMonth + 1, 0);
    let days = []
    for (let a = 1; a <= selectedDate.getDate(); a++) {
      days.push({date: a, weekday: weekday(new Date(selectedYear, selectedMonth, a).getDay()), events: []})
    }
    return days;
  }

  const nextMonth = () => {
    if (month < 11) {
      setMonth(month + 1);
    } else {
      setMonth(0);
      setYear(year + 1);
    }
  }

  const previousMonth = () => {
    if (month > 0) {
      setMonth(month - 1);
    } else {
      setYear(year - 1);
      setMonth(11);
    }
  }

  const addButton = () => {
    if (isUserLeader || isAdministrator) {
      return <Button size={"large"} variant="text" onClick = {setCreate} ><AddIcon/></Button>;
    }
  }

  const render = (start, stop) => {
    return (
      data && data
      .slice(start, stop)
      .map(day => (
        <div className={classes.day}>
          <div className={classes.date}>{day.date}</div>
          <div className={classes.weekday}>{day.weekday.substring(0, 3)}</div>
          <div>
            {day.events.map(event => (
              <PlainCalendarItem id={event.id} title={event.title} eventType={event.eventType} status={event.status} key={event.id} handleOpen={handleOpen}/>
            ))}
          </div>
        </div>
      ))
    )
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.headerRow}>
        <Typography className={classes.heading}>{nameOfMonth(month)} {year}</Typography>
        {addButton()}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {render(0, 16)}
        </Grid>
        <Grid item xs={12} md={6}>
          {render(16, 31)}
        </Grid>
      </Grid>
      <EventDialog id={currentEventId}
                   open={open}
                   handleClose={handleClose}/>
      <CreateEvent open={create} handleClose={handleClose} handleCreate={handleCreate}/>

      <div className={classes.buttonContainer}>

        <IconButton IconButton color="info" className={classes.button} onClick={() => {
          previousMonth();
        }}>
          <SkipPreviousIcon/>
        </IconButton>

        <IconButton color="info" className={classes.button} onClick={() => {
          nextMonth();
        }}>
          <SkipNextIcon/>
        </IconButton>
      </div>
    </Paper>
  );
}
