import React from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import InCaseOfEmergencyList from "../components/ice/InCaseOfEmergencyList";
import UserMembershipsAndSections
  from "../components/members/UserMembershipsAndSections";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  ice: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  }
}));

export default function MyAccount() {

  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Grid container
              justifyContent="center"
              spacing={3}
              direction="row"
              alignItems="stretch"
        >
          <Grid item xs={12} md={12} lg={5} xl={5}>
            <UserMembershipsAndSections/>
          </Grid>
          <Grid item xs={12} md={12} lg={5} xl={5}>
            <InCaseOfEmergencyList className={classes.ice}/>
          </Grid>
        </Grid>

      </div>
  );
}
