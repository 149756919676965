import React, {useEffect, useState} from 'react';
import iceService from "../../services/ice-service";
import {makeStyles} from "@material-ui/core";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../Loading";
import InCaseOfEmergency from "./InCaseOfEmergency";
import CreateInCaseOfEmergency from "./CreateIncaseOfEmergency";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "center",
    flex: 1
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
}));

export default function InCaseOfEmergencyList(_props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState([]);
  const [create, setCreate] = React.useState(false);
  const [ice, setIce] = useState([])
  const [loading, setLoading] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleOpen = (id) => {
    iceService.get(id).then(fetchedData => {
      setIce(fetchedData)
      setOpen(true)
    })
  }

  const handleClose = () => {
    setOpen(false)
    setCreate(false)
  }
  const addButton = () => {
    return <Button size={"large"} variant="text" onClick={setCreate}><AddIcon/></Button>;
  }
  const handleCreate = (name, relation, primaryPhone, secondaryPhone) => {
    iceService.create(name, relation, primaryPhone, secondaryPhone)
      .then(() => {
        enqueueSnackbar(name + ' sparat', {variant: 'success'})
        load()
        setCreate(false)
      })
  }

  const handleEdit = (id, name, relation, primaryPhone, secondaryPhone) => {
    iceService.update(id, name, relation, primaryPhone, secondaryPhone)
      .then(() => {
        enqueueSnackbar(name + " sparat", {variant: "success"});
        load()
      })
  }

  const handleDelete = () => {
    iceService.remove(ice.id)
      .then(() => {
        enqueueSnackbar("ICE-kontakten är nu borttagen", {variant: "success"})
        load();
      })
    setOpen(false);
  }

  const load = () => {
    setLoading(true)
    iceService.all().then(newIces => {
      console.log(newIces)
      setData(newIces);
      setLoading(false);
    })
  }

  useEffect(() => {
    load()
  }, []);

  return (
    <Paper sx={{overflow: 'hidden'}}>
      <div className={classes.topRow}>
        <Typography className={classes.heading}>In Case of Emergency kontakter</Typography>
        {addButton()}
      </div>
      <Loading loading={loading}/>
      <TableContainer component={Paper} sx={{maxHeight: "80vh"}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Relation</TableCell>
              <TableCell>Telefonnummer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(ice => (
                <TableRow hover key={ice.id} onClick={() => handleOpen(ice.id)}>
                  <TableCell>
                    {ice.name}
                  </TableCell>
                  <TableCell>{ice.relation}</TableCell>
                  <TableCell>{ice.primaryPhone}</TableCell>
                  <TableCell>{ice.secondaryPhone}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InCaseOfEmergency iceId={ice.id} handleClose={handleClose} handleEdit={handleEdit} handleDelete={handleDelete} open={open}/>
      <CreateInCaseOfEmergency open={create} handleCreate={handleCreate} handleClose={handleClose}/>
    </Paper>
  );

}
