import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import AuthService from '../services/auth-service';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const jwt = AuthService.getJwt();

    return (
        <Route {...rest} render={props => {
            if (!jwt) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
            }

            // authorised so return component
            return <Component {...props} />
        }}/>
    )
}

export default PrivateRoute;
