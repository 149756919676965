import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import BookingService from "../../services/booking-service";
import authService from "../../services/auth-service";
import {DirectionsBoat, EmojiPeople} from "@mui/icons-material";
import {List, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import Loading from "../Loading";
import Utils from "../../services/utils";
import EventDialog from "../events/EventDialog";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
    color: theme.palette.text.secondary,
  },
  scroll: {
    overflow: "scroll",
    height: "auto"
  }
}))

export default function Assignments() {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventDialog, setEventDialog] = useState([]);

  const handleOpen = (event) => {
    setEventDialog(event)
    setOpen(true)
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    BookingService.getUsersUpcomingAssignments().then(data => {
      setEvents(data);
      setLoading(false);
    })
  }, []);

  const setAssignmentTypeIcon = event => {
    if (event.boatMaster !== null && event.boatMaster.id === parseInt(authService.getUserId())) {
      return <DirectionsBoat/>
    }
    return <EmojiPeople/>
  }


  return (
    <Paper className={classes.container}>
      <Typography className={classes.heading}>Kommande uppdrag</Typography>
      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            {events ? events.map((event) => (
              <ListItem key={event.id} onClick={() => handleOpen(event)}>
                <ListItemIcon>{setAssignmentTypeIcon(event)}</ListItemIcon>
                <ListItemText>{Utils.formatDateFromMillis(event.startDate)} - {(event.title)}</ListItemText>
              </ListItem>
            )) : ''
            }
          </List>
        </Loading>
      </div>
      <EventDialog id={eventDialog.id} open={open} handleClose={handleClose}/>
    </Paper>
  )
}
