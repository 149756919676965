import {makeStyles} from "@material-ui/core/styles";
import {blueGrey} from "@mui/material/colors";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import CertificateService from "../../services/certificate-service";
import ReactQuill from "react-quill";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";
import AuthService  from "../../services/auth-service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "center",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: "700px"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  bookButton: {
    background: "rgb(255,255,255)",
    '&:hover': {
      backgroundColor: blueGrey[100],
    }
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },

  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  radioGroup: {
    padding: 5,
    margin: 5,
  },
}))
 export default function Certificate(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {certificateId, handleClose, handleDelete, handleEdit, open} = props;
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [organization, setOrganization] = useState("");
  const [course, setCourse] = useState("");
  const [weight, setWeight] = useState(0);
  const [description, setDescription] = useState("")


   useEffect(() => {
     if (certificateId) {
       CertificateService.getById(certificateId).then((data) => {
         setOrganization(data.organization);
         setCourse(data.course);
         setWeight(data.weight);
         setDescription(data.description);
       })
     }
   }, [open, certificateId])


  const handleWeight = event => {
    setWeight(event.target.value);
  };

  const handleSave = () => {
    setEditMode(false);
    handleEdit(certificateId, organization, course, weight, description);
  };

  const handleRemove = () => {
    setEditMode(false)
    handleDelete(certificateId)
  }

  return (
    <Dialog
    open={open}
    fullScreen={fullScreen}
    maxWidth={"md"}
    fullWidth={true}
    onClose={handleClose}
    className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>{course}</DialogTitle>
      <DialogContent>
        { !editMode &&
        <div>
          <Typography className={classes.textInfo} >Organisation: {organization}</Typography>
          <Typography className={classes.textInfo}>Kurs: {course}</Typography>
        </div>
        }
        { editMode &&
        <TextField
          id="organisation"
          onChange={(e => setOrganization(e.target.value))}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Organisation"
          name="Organisation"
          value={organization}
        />}
        { editMode &&
          <div className={classes.newRow}>
        <TextField
          id="organisation"
          onChange={(e => setCourse(e.target.value))}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Course"
          name="Course"
          value={course}
        />
          </div>
        }
         { editMode &&
          <div className={classes.newRow}>
        <TextField
          id="weight"
          onChange={(e => handleWeight(e))}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Weight"
          name="Weight"
          value={weight}
        />
          </div>
        }
        <ReactQuill theme={editMode ? "snow"  : null} value={description} onChange={setDescription} readOnly={!editMode}/>
      </DialogContent>
      <DialogActions>
        {!editMode &&
        <>
          <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ta bort</Button>
          <ConfirmDialog
            title="Ta bort certifikat?"
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            onConfirm={handleRemove}
            >Är du säker på att du vill ta bort certifikatet?</ConfirmDialog>
          {AuthService.isAdministrator() ? (
            <Button size={"medium"} color="secondary" onClick={() => setEditMode(true)}>Redigera</Button>
          ) : null }
          <Button size={"medium"} color="primary" onClick={handleClose}>Stäng</Button>
        </>
        }
          {editMode && <>
          <Button size={"medium"} color="secondary" onClick={handleClose}>Stäng</Button>
          <Button size={"medium"} color="primary" onClick={handleSave}>Spara</Button>
        </>
        }
      </DialogActions>

    </Dialog>
  )
 }
