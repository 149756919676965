import {useEffect, useState} from "react";
import AuthService from "../services/auth-service";

export function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const jwt = AuthService.getJwt();

    if (jwt) {
      setCurrentUser(jwt);
    } else {
      setCurrentUser(null)
    }
  }, []);

  return currentUser;
}
