import React, {createRef, useEffect, useState} from 'react'
import {Paper, TextField} from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Loading from "../Loading";
import ChatService from "../../services/chat-service";
import Utils from "../../services/utils";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import authHeader from "../../services/auth-header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: '1',
    textAlign: "left",
    padding: theme.spacing(2, 2, 2, 2)
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 0, 2, 0)
  },
  scroll: {
    height: "300px",
    overflow: "auto",
  },
  messages: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  message: {
    margin: theme.spacing(0, 0, 1, 0),
    fontSize: theme.typography.pxToRem(8)
  },
  msgHeader: {
    fontSize: theme.typography.pxToRem(14)
  },
  msgText: {
    fontSize: theme.typography.pxToRem(14)
  }
}))

const Chat = () => {

  const classes = useStyles()
  const [messages, setMessages] = useState([])
  const [chatMessage, setChatMessage] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const messagesEndRef = createRef()

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "auto"})
  }

  const connect = () => {
    const socket = new SockJS("http://localhost:3001/api/chat", authHeader());
    const stompClient = Stomp.over(socket);

    stompClient.connect(authHeader(), () => {
      stompClient.subscribe("/topic/chat", onMessageReceived, authHeader());
    });
  }

  const onMessageReceived = (payload) => {
    const chatMsg = JSON.parse(payload.body)
    setMessages(() => [chatMsg, ...messages])
  }

  const send = () => {
    ChatService.sendMessage(chatMessage)
    .then(() => setChatMessage([]))
    .catch(chatError => setError(chatError))
  }

  useEffect(() => {
    setLoading(true)
    ChatService.latest().then(data => {
      setMessages(data);
      setLoading(false)
      connect()
    })
  }, []);

  useEffect(() => {
    scrollToBottom()
  }, [messagesEndRef]);

  return (
    <Paper className={classes.root}>
      <Typography className={classes.heading}>Chatten {error}</Typography>
      <div className={classes.messages}>
        <Loading loading={loading}>
          <div className={classes.scroll}>
            {messages.map((message) => (
              <div key={message.id} className={classes.message}>
                <Typography className={classes.msgHeader}>
                  {Utils.formatDateTimeFromMillis(message.created)} - {message.author}
                </Typography>
                <Typography className={classes.msgText}>{message.message}</Typography>
              </div>
            )).reverse()}
            <div ref={messagesEndRef}/>
          </div>
        </Loading>
      </div>
      <div>
        <TextField fullWidth label="Meddelande" id="msgSend" onChange={e => {
          setChatMessage(e.target.value)
        }} value={chatMessage} onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            send()
            ev.preventDefault();
          }
        }}/>
      </div>
    </Paper>
  )
}

export default Chat
