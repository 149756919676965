import React from 'react';
import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  AccountBalance,
  Cancel,
  Contacts,
  Edit,
  Print
} from "@material-ui/icons";
import eventService from "../../services/event-service";


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: "center"
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export default function LeaderMenu(props) {
  const classes = useStyles();
  const {id, handleCancelDialog, handleEditDialog} = props


  const handleDownloadIce = () => {
    eventService.downloadIce(id)
  }

  const handleDownloadDiveProtocol = () => {
    eventService.downloadDiveProtocol(id)
  }

  const handleDownloadAccounting = () => {
    eventService.downloadAccounting(id)
  }


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item>
        <Button
          className={classes.button}
          size={"small"}
          startIcon={<Edit/>}
          onClick={handleEditDialog}
        >
          Ändra
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          size={"small"}
          startIcon={<Cancel/>}
          onClick={handleCancelDialog}
        >
          Ställ in
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          size={"small"}
          startIcon={<Print/>}
          onClick={handleDownloadDiveProtocol}
        >
          Dykprotokoll
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          size={"small"}
          startIcon={<AccountBalance/>}
          onClick={handleDownloadAccounting}
        >
          Redovisning
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          size={"small"}
          startIcon={<Contacts/>}
          onClick={handleDownloadIce}
        >
          ICE
        </Button>
      </Grid>
    </Grid>
  );
}
