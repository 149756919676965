import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material"
import {
  makeStyles,
} from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '700px'
  },
  dialogContent: {
    height: "50vh"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  rte: {
    height: "70%"
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  radioGroup: {
    padding: 5,
    margin: 5,
  },

  maxDepth: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  }
}));

export default function CreateDiveSite(props) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const {handleClose, handleCreate, open} = props
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [diveType, setDiveType] = useState("Vrak");
  const [level, setLevel] = useState("Lätt");
  const [mapType, setMapType] = useState("WGS84");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [maxDepth, setMaxDepth] = useState("");
  const [demandsBoat, setDemandsBoat] = useState(true);


  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleLevel = (event) => {
    setLevel(event.target.value)
  }
  const handleDiveTypes = (event) => {
    setDiveType(event.target.value)
  }

  const handleMapTypes = (event) => {
    setMapType(event.target.value)
  }

  const handleDemandsBoat = (event) => {
    if(event.target.value === "on") {
      setDemandsBoat(true)
    } else {
      setDemandsBoat(false)
    }
  }


  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle}>Ny dykplats</DialogTitle>
      <DialogContent className={classes.dialogContent}>


       <FormControl className={classes.radioGroup}>
          <FormLabel>Typ av dykning</FormLabel>
          <RadioGroup
            row
            aria-labelledby="diveTypeLabel"
            name="row-diveType"
            value={diveType}
            onChange={handleDiveTypes}
            >
            <FormControlLabel value="Vrak" control={<Radio />} label="Vrak" />
            <FormControlLabel value="Natur" control={<Radio />} label="Natur" />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.radioGroup}>
          <FormLabel>Svårighetsgrad</FormLabel>
          <RadioGroup
            row
            aria-labelledby="diffucultyLabel"
            name="row-difficulty"
            value={level}
            onChange={handleLevel}
          >
            <FormControlLabel value="Lätt" control={<Radio />} label="Lätt" />
            <FormControlLabel value="Medel" control={<Radio />} label="Medel" />
            <FormControlLabel value="Svår" control={<Radio />} label="Svår" />
          </RadioGroup>
        </FormControl>
        <FormControl className={classes.radioGroup}>
          <FormLabel>Position (Kart/Sjökort-datum)</FormLabel>
          <RadioGroup
            row
            aria-labelledby="mapType"
            name="row-mapType"
            value={mapType}
            onChange={handleMapTypes}
          >
            <FormControlLabel value="WGS84" control={<Radio />} label="WGS84" />
            <FormControlLabel value="RT90" control={<Radio />} label="RT90" />
          </RadioGroup>
        </FormControl>

        <div className={classes.newRow}>
        <TextField
          id="lat"
          onChange={e => setLat(e.target.value)}
          required
          label="Lat"
          name="Lat"
          value={lat}
          autoFocus/>
        <TextField
          id="long"
          onChange={e => setLong(e.target.value)}
          required
          label="Long"
          name="Long"
          value={long}
          autoFocus/>
        <div className={classes.maxDepth}>
          <TextField
            id="maxDepth"
            onChange={e => setMaxDepth(e.target.value)}
            required
            label="Maxdjup i meter"
            name="Maxdepth"
            value={maxDepth}
            autoFocus/>
        </div>
        <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked onChange={handleDemandsBoat}/>} label="Kräver båt" />
        </FormGroup>
        </div>
        <TextField
          id="title"
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Titel"
          name="Title"
          value={title}
          autoFocus/>

        <ReactQuill className={classes.rte}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    value={description}
                    onChange={setDescription}/>
      </DialogContent>
      <DialogActions>
        <Button size={"medium"} color="secondary" onClick={handleClose}>Avbryt</Button>
        <Button size={"medium"} color="primary" onClick={() =>
          handleCreate({
            title: title,
            description: description,
            level: level,
            maxDepth: maxDepth,
            lat: lat,
            long: long,
            demandsBoat: demandsBoat,
            mapType: mapType,
            diveType: diveType
          })}>Spara</Button>
      </DialogActions>
    </Dialog>
  );
}
