import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import emailService from "../../services/email-service";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Utils from "../../services/utils";
import {Button, Grid, List, ListItem, ListItemButton} from "@mui/material";
import EmailDetailDialog from "./EmailDetailDialog";
import Loading from "../Loading";
import {Add} from "@mui/icons-material";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    height: "80vh"
  }
}));

export default function Emails() {

  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [createMode, setCreateMode] = React.useState(false)
  const [selectedEmail, setSelectedEmail] = useState(0)

  const load = () => {
    setLoading(true)
    emailService.all().then(data => {
      setEmails(data);
      setLoading(false)
    })
  }

  const handleCreateNewEmail = () => {
    setCreateMode(true)
    setOpen(true)
  }

  const handleOnClick = (id) => {
    setSelectedEmail(id);
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedEmail([])
    setOpen(false)
    setCreateMode(false)
  }

  const handleCreate = (subject, body, toSections, toGroups) => {
    emailService.create(subject, body, toSections, toGroups)
    .then(() => {
      enqueueSnackbar(subject + ' skickat', {variant: 'success'})
      setCreateMode(false)
      setOpen(false)
      setLoading(false)
      load()
    })
    .catch(msg => {
      enqueueSnackbar(subject + ' kunde inte skickas på grund av ' + msg, {variant: 'error'})
      setLoading(false)
    })
  }

  useEffect(() => {
    load()
  }, []);

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item xs={10}>
          <Typography className={classes.heading}>Mail utskick</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.heading}>
            <Button onClick={handleCreateNewEmail}><Add/></Button>
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.scroll}>
        <Loading loading={loading}>
          <Grid container>
            <List dense>
              {emails.map((row) => (
                  <ListItem key={row.id} disablePadding>
                    <ListItemButton id={row.id} onClick={() => handleOnClick(row.id)}>
                      <Grid item xs={4}>{Utils.formatDateTimeFromMillis(row.created)}</Grid>
                      <Grid item xs={8}>{row.subject}</Grid>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Grid>
        </Loading>
      </div>
      <EmailDetailDialog emailId={selectedEmail} open={open} createMode={createMode} handleClose={handleClose} handleCreate={handleCreate}/>
    </Paper>
  );
}
