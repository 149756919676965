import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/member";

const myself = () => {
  return axios
  .get(API_URL + "/myself", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const applicants = () => {
  return axios
  .get(API_URL + "/new", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const changeStatus = (id, status) => {
  return axios
  .put(API_URL + "/" + id + "/status/" + status, {}, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const changeAccount = (id, account) => {
  return axios
  .put(API_URL + "/" + id + "/account-type/" + account, {}, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const list = () => {
  return axios
  .get(API_URL + "/list", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const listNotPayed = () => {
  return axios
  .get(API_URL + "/list-not-paid", {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const get = (id) => {
  return axios
  .get(API_URL + "/" + id, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const search = (name) => {
  return axios
  .get(API_URL + "/search/" + name, {headers: authHeader()})
  .then((response) => {
    return response.data;
  });
};

const getUserMemberships = (id) => {

  return axios
    .get(API_URL + "/"+ id + "/memberships", {headers: authHeader()})
    .then((response) => {
    return response.data;
})
}

const getMembersBySectionAndYear = (sectionId, year) => {

  return axios
  .get(API_URL + "/section/ "+ sectionId + "/year/" + year, {headers: authHeader()})
  .then((response) => {
    return response.data;
  })
}

const MembersService = {
  myself,
  applicants,
  get,
  changeAccount,
  changeStatus,
  list,
  listNotPayed,
  search,
  getUserMemberships,
  getMembersBySectionAndYear
}

export default MembersService
