import React, {useEffect, useState} from 'react'
import {Grid, Paper, Typography} from "@mui/material"
import eventStatistics from '../../services/statistics-service'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    padding: theme.spacing(2)
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 1, 0, 1)
  },
  content: {
    padding: theme.spacing(1)
  }
}))

const Top10BoatMastersThisYear = (props) => {
  const classes = useStyles()
  const [membershipData, setMembershipData] = useState([])

  useEffect(() => {
    eventStatistics.eventsBoatMasterByYear(props.year).then(leaders => {
      setMembershipData(leaders)
    })

  }, [props.year])

  return (
    <Paper className={classes.root}>
      <Typography className={classes.heading}>Top 10 Båtförare {props.year}</Typography>
      <div className={classes.content}>
        <Grid container spacing={2}>
          {membershipData.map((leader => (
              <>
                <Grid item xs={10}>
                  {leader.firstName + ' ' + leader.lastName}
                </Grid>
                <Grid item xs={2}>
                  {leader.stat}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </div>
    </Paper>
  )
}

export default Top10BoatMastersThisYear
