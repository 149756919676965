import React, {useState} from 'react';
import {
  Dialog, DialogActions,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import eventService from "../../services/event-service";
import {useSnackbar} from "notistack";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: "center",
    padding: theme.spacing(2)
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: "800px"
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  listSelector:{
    marginTop: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    }
}));

export default function CancelEventDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {enqueueSnackbar} = useSnackbar()
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const {cancelDialog, eventID, handleCancelDialog} = props
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");


  const handleDeleteEvent = () => {
    if (message !== "") {
      eventService.cancel(eventID, message, reason)
        .then(() => {
          enqueueSnackbar('Evenemanget är nu inställt', {variant: 'success'})
          setConfirmDeleteOpen(false)
          handleCancelDialog()
        })
    } else {
      enqueueSnackbar('Skriv en förklaring till varför evenemanget är inställt', {variant: "error"})
    }

  }


  const handleReason = (event) => {
    setReason(event.target.value);
  }

  const handleMessage = (event) => {
    setMessage(event.target.value);
  }

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        className={classes.root}
        open={cancelDialog}
        onClose={handleCancelDialog}
      >
        <DialogTitle className={classes.dialogTitle}>Ställ in evenemang</DialogTitle>
        <FormControl fullWidth>
          <InputLabel id="cancelation-reason">Anledning</InputLabel>
          <Select
            className={classes.listSelector}
            labelId="reason-for-cancelation"
            id="cancelation"
            value={reason}
            label="Anledning"
            onChange={handleReason}
            defaultValue={"WEATHER"}
            sx={{ m: 2 }}
          >
            <MenuItem value={"WEATHER"}>Dåligt väder</MenuItem>
            <MenuItem value={"TO_FEW_PARTICIPANTS"}>För få deltagare</MenuItem>
            <MenuItem value={"SICKNES"}>Sjukdom</MenuItem>
            <MenuItem value={"BOAT"}>Båten är sönder</MenuItem>
            <MenuItem value={"OTHER"}>Övrigt</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          id="outlined-multiline-static"
          label="Meddelande till deltagare"
          multiline
          rows={4}
          defaultValue="Meddelande till deltagare"
          value={message}
          sx={{ m: 2 }}
          onChange={handleMessage}
        />

        <DialogActions>
              <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ställ in</Button>
              <ConfirmDialog
                title="Ställ in evenemang?"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={handleDeleteEvent}
              >
                Är du säker på att du vill ställa in evenemanget?
              </ConfirmDialog>
          <Button size={"medium"} color="secondary" onClick={handleCancelDialog}>Stäng</Button>
        </DialogActions>
      </Dialog>
    )
}
