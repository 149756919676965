import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Base64} from "js-base64";
import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import emailService from "../../services/email-service";
import {useSnackbar} from "notistack";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: theme.spacing(1)
  }
}))

export default function EmailTemplateDetailDialog(props) {

  const classes = useStyles();
  const {templateId, open, create, handleClose} = props
  const [editMode, setEditMode] = useState(false)
  const [content, setContent] = useState('')
  const [subject, setSubject] = useState('')
  const [name, setName] = useState('')
  const [lang, setLang] = useState('sv')
  const {enqueueSnackbar} = useSnackbar();

  const edit = () => {
    setEditMode(true)
  }

  const createNew = () => {
    emailService.createTemplate(name, subject, lang, Base64.encode(content))
    .then(() => {
      setEditMode(false)
      enqueueSnackbar(name + ' ' + lang + ' sparat', {variant: 'success'})
      setContent('')
      setSubject('')
      setName('')
      setLang('sv')
      handleClose()
    })
    .catch(reason => {
      enqueueSnackbar('Kunde inte spara ' + name + ' ' + lang + ' på grund av ' + reason)
    })
  }

  const updateExisting = () => {
    emailService.updateTemplate(templateId, name, subject, lang, Base64.encode(content))
    .then(() => {
      setEditMode(false)
      enqueueSnackbar(name + ' ' + lang + ' sparat', {variant: 'success'})
      handleClose()
    })
    .catch(reason => {
      enqueueSnackbar('Kunde inte spara ' + name + ' ' + lang + ' på grund av ' + reason)
    })
  }

  const save = () => {
    if (create === undefined || create === false) {
      updateExisting()
    } else {
      createNew()
    }
  }

  useEffect(() => {
    if (create !== undefined || create === true) {
      setEditMode(true)
    }

    if (templateId != null) {
      setEditMode(false)
      emailService.getTemplate(templateId).then(data => {
        setContent(Base64.decode(data.content));
        setSubject(data.subject)
        setName(data.name)
        setLang(data.languageCode)
      })
    }
  }, [templateId, open, create])

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{name} [{lang}] {subject}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!editMode && <div dangerouslySetInnerHTML={{__html: content}}/>}
        {editMode &&
          <Grid className={classes.dialogContent} spacing={2} container>
            <Grid item xs={5}>
              <TextField id="outlined-basic" label="Namn" variant="outlined" value={name} onChange={event => setName(event.target.value)} fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextField id="outlined-basic" label="Titel" variant="outlined" value={subject} onChange={event => setSubject(event.target.value)} fullWidth/>
            </Grid>
            <Grid item xs={1}>
              <TextField id="outlined-basic" label="Språk" variant="outlined" value={lang} onChange={event => setLang(event.target.value)}/>
            </Grid>
            <Grid item xs={12}>
              <Editor
                value={content}
                onValueChange={(value) => setContent(value)}
                highlight={(highlightedContent) => highlight(highlightedContent, languages.js)}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                  height: "300px"
                }}
              />
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        {!editMode && (
          <>
            <Button color="primary" onClick={handleClose}>Stäng</Button>
            <Button color="secondary" onClick={edit}>Redigera</Button>
          </>
        )}
        {editMode && (
          <>
            <Button color="secondary" onClick={handleClose}>Stäng</Button>
            <Button color="primary" onClick={save}>Spara</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
