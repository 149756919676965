import authService from "./auth-service";
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/booking"
const USER_ID = authService.getUserId();

const getUsersUpcommingBookings = () => {
  return axios
    .get(API_URL + "/" + USER_ID, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}


const getUsersUpcomingAssignments = () => {
  return axios
    .get(API_URL + "/" + USER_ID + "/assignments", {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const BookingService = {
  getUsersUpcommingBookings,
  getUsersUpcomingAssignments
}

export default BookingService;
