import axios from "axios";
import authHeader from "./auth-header"

const API_URL = process.env.REACT_APP_API_URL + '/api/userrole';

const getAdminOrLeaders = () => {
  return axios
    .get(API_URL + '/adminorleader', {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const getBoatMasters = () => {
  return axios
    .get(API_URL + '/boatmasters', {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
}

const UserRoleService = {
  getAdminOrLeaders,
  getBoatMasters
}

export default UserRoleService;
