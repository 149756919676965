import React, {useEffect, useState} from 'react'
import {Paper, Typography} from "@mui/material"
import eventStatistics from '../../services/statistics-service'
import {makeStyles} from '@material-ui/core/styles'
import {ResponsiveBar} from "@nivo/bar";

const useStyles = makeStyles(theme => ({
  root: {
    height: "340px",
    padding: theme.spacing(2)
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 1, 0, 1)
  },
}))

const HallOfFameDiveLeaders = () => {
  const classes = useStyles()
  const [membershipData, setMembershipData] = useState([])

  useEffect(() => {
    eventStatistics.eventsDiveMasterPerYear().then(leaders => {
      const eventData = leaders.reverse().map(leader => ({id: leader.firstName + ' ' + leader.lastName, label: leader.firstName, value: leader.stat}))
      setMembershipData(eventData)
    })

  }, [])

  return (
    <Paper className={classes.root}>
      <Typography className={classes.heading}>Hall of fame: Dykledare [2008-{new Date().getFullYear()}]</Typography>
      <ResponsiveBar
        data={membershipData}
        margin={{top: 10, right: 40, bottom: 40, left: 120}}
        layout="horizontal"
        innerRadius={0.6}
        padAngle={0.5}
        cornerRadius={5}
        arcLinkLabelsColor={{
          from: 'color',
        }}
        arcLinkLabelsThickness={3}
        arcLinkLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 1.2]],
        }}
      />
    </Paper>
  )
}

export default HallOfFameDiveLeaders
