import React, {useEffect, useState} from 'react'
import {Paper, Typography} from "@mui/material"
import eventStatistics from '../../services/statistics-service'
import {makeStyles} from '@material-ui/core/styles'
import {ResponsiveLine} from "@nivo/line";

const useStyles = makeStyles(theme => ({
  root: {
    height: "340px",
    padding: theme.spacing(2)
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 1, 0, 1)
  },
}))

const EventsStats = () => {
  const classes = useStyles()
  const [plannedDives, setPlannedDives] = useState({id: 'Planerade', data: []})
  const [canceledDives, setCanceledDives] = useState({id: 'Inställda', data: []})

  useEffect(() => {
    eventStatistics.eventsPlanned().then(events => {
      const eventData = events.map(event => ({x: event.year, y: event.stats}))
      setPlannedDives({
        id: 'Planerade',
        data: eventData
      })
    })

    eventStatistics.eventsCanceled().then(events => {
      const eventData = events.map(event => ({x: event.year, y: event.stats}))
      setCanceledDives({
        id: 'Inställda',
        data: eventData
      })
    })
  }, [])

  return (
    <Paper className={classes.root}>
      <Typography className={classes.heading}>Plannerade- och inställda dyk</Typography>
      <ResponsiveLine
        data={[plannedDives, canceledDives]}
        margin={{top: 30, right: 20, bottom: 80, left: 30}}
        xScale={{type: 'linear', min: 2008, max: new Date().getFullYear()}}
        yScale={{type: 'linear'}}
        yFormat=" >-.2f"
        curve="monotoneX"
        useMesh={true}
        enableSlices={false}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 50,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </Paper>
  )
}

export default EventsStats
