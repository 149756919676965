import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Grid, Link, List, ListItem} from "@mui/material";
import EventInformation from "./EventInformation";

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function EventTabs(props) {
  const [value, setValue] = React.useState(0);
  const {description, diveSite, bookedUsers} = props;

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Beskrivning"/>
          <Tab label="Plats"/>
          <Tab label="Anmälda deltagare"/>
          <Tab label="Övrig information"/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div dangerouslySetInnerHTML={{__html: description}}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div dangerouslySetInnerHTML={{__html: diveSite}}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Bokade:</Typography>
            <List dense>
              {bookedUsers
              .filter(function (bookedUser) {
                return bookedUser.status === "Bokad"
              })
              .map((bookedUser) => (
                <ListItem key={bookedUser.id}>
                  <Grid container>
                    <Grid item xs={7}>{bookedUser.name}</Grid>
                    <Grid item xs={5}><Link href="tel:{bookedUser.phone}">{bookedUser.phone}</Link></Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography>Avbokade:</Typography>
            <List dense>
              {bookedUsers
              .filter(function (canceledReservation) {
                return canceledReservation.status === "Avbokat"
              })
              .map((canceledReservation) => (
                <ListItem key={canceledReservation.id}>
                  <Grid container>
                    <Grid item xs={7}>{canceledReservation.name}</Grid>
                    <Grid item xs={5}><Link href="tel:{bookedUser.phone}">{canceledReservation.phone}</Link></Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel index={3} value={value}>
        <EventInformation/>
      </TabPanel>
    </Box>
  );
}
