import React from 'react'
import Emails from '../components/emails/Emails'
import {Grid} from "@material-ui/core";
import EmailTemplates from "../components/emails/EmailTemplates";
import AuthService from '../services/auth-service'

export default function EmailsContainer() {

  return (
    <Grid container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
    >
      <Grid item xs={12} lg>
        <Emails/>
      </Grid>
      <Grid item xs={12} lg>
        { AuthService.isAdministrator() && <EmailTemplates/> }
      </Grid>
    </Grid>
  );
}
