import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import EventService from "../../services/event-service";
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import EventDialog from "./EventDialog";
import Utils from "../../services/utils";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
    color: theme.palette.text.secondary,
  },
  scroll: {
    overflow: "scroll",
    height: "320px"
  }
}));

export default function Events() {

  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [events, setEvents] = useState([]);
  const [eventDialog, setEventDialog] = useState([])

  const handleOpen = (event) => {
    setEventDialog(event)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    load()
  }

  const load = () => {
    setLoading(true);
    EventService.upcoming().then(data => {
      setEvents(data);
      setLoading(false);
    })
  }

  useEffect(() => {
    load();
  }, []);

  const setEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 'TRIP':
      case 'trip':
        return <DirectionsBoatIcon/>
      case 'COURSE':
      case 'course':
        return <SchoolIcon/>
      case 'MEETING':
      case 'meeting':
        return <GroupsIcon/>
      default:
        return <EventIcon/>
    }
  }



  return (
    <Paper className={classes.container}>
      <Typography className={classes.heading}>Kommande evenemang</Typography>

      <div className={classes.scroll}>
        <Loading loading={loading}>
          <List dense>
            {events ? events.map((event) => (
                <ListItem key={event.id} button onClick={() => handleOpen(event)}>
                  <ListItemIcon>{setEventTypeIcon(event.eventType)}</ListItemIcon>
                  <ListItemText primaryTypographyProps={{
                    color: event.status === 'CANCELED' ? 'error' : 'info',
                  }}>{Utils.formatDateFromMillis(event.startDate)}&nbsp;&nbsp;{event.status === 'CANCELED' ? "Inställt: " : ""}{event.title}</ListItemText>
                </ListItem>
              )
            ) : ''}
          </List>
        </Loading>
      </div>
      <EventDialog id={eventDialog.id} open={open} handleClose={handleClose} />
    </Paper>
  );
}
