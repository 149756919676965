import React from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function AdminRoles() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent={'center'} spacing={2}>
        Administrera roller
      </Grid>
    </div>
  );
}
