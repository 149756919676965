import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import EventsStats from "../components/statistics/EventsStats";
import MembershipBySection from "../components/statistics/MembershipBySection";
import MembersByMembership from "../components/statistics/MembersByMembership";
import HallOfFameDiveLeaders
  from "../components/statistics/HallOfFameDiveLeaders";
import HallOfFameBoatMasters
  from "../components/statistics/HallOfFameBoatMasters";
import Top10DiveLeadersThisYear
  from "../components/statistics/Top10DiveLeadersThisYear";
import Top10BoatMastersThisYear
  from "../components/statistics/Top10BoatMastersThisYear";
import {Paper, TextField, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Statistics() {

  const classes = useStyles();
  const [year, setYear] = useState(new Date().getFullYear())
  const handleTextChange = (e) => {
    setYear(e.target.value);
  }

  return (
      <div className={classes.root}>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '10px',
                  paddingBottom: '10px'
                }}
            >
              <Typography sx={{marginRight: '10px'}}>Statistik för:</Typography>
                <TextField
                    value={year}
                    onChange={handleTextChange}
                    size="small"
                    label="Årtal"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}/>

            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MembershipBySection year={year}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MembersByMembership year={year}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <EventsStats/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Top10DiveLeadersThisYear year={year}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Top10BoatMastersThisYear year={year}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <HallOfFameDiveLeaders/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <HallOfFameBoatMasters/>
          </Grid>
        </Grid>
      </div>
  );
}
