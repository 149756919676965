import React, {useEffect, useState} from 'react'
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import "./MapForDiveSites.css";
import DiveSiteService from "../../services/divesite-service";
import DiveSite from "./DiveSite";
import {Button,} from "@mui/material";
import {useSnackbar} from "notistack";
import Loading from "../Loading";

export default function MapForDiveSites() {
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false);
  const [diveSite, setDiveSite] = useState([]);
  const {enqueueSnackbar} = useSnackbar();

  const load = () => {
    setLoading(true)
    DiveSiteService.all().then(newDiveSites => {
      setData(newDiveSites);
      setLoading(false);
    })
  }

  useEffect(() => {
    DiveSiteService.all().then(fetchedDivesites => {
      setData(fetchedDivesites);
    })
  }, []);

  useEffect(() => {
    load()
  }, []);

  const handleOpen = () => {
    DiveSiteService.get(diveSite.id).then(fetchedData => {
      setDiveSite(fetchedData)
      setOpen(true)
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEdit = ({id, title, description, level, maxDepth, lat, long,
      demandsBoat, mapType, diveType}) => {
    DiveSiteService.update(id, title, description, level, maxDepth, lat, long,
        demandsBoat, mapType, diveType)
    .then(() => {
      enqueueSnackbar(title + " sparat", {variant: "success"});
      load()
    })
  }

  const handleDelete = () => {
    DiveSiteService.remove(diveSite.id)
    .then(() => {
      enqueueSnackbar("Dykplatsen är nu borttagen", {variant: "success"})
      load();
    })
    setOpen(false);
  }

  return (
      <div>
        <Loading loading={loading}/>
        <MapContainer center={[55.58551239053252, 12.924506213908696]} zoom={13}
                      scrollWheelZoom={false}>
          <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data
          .filter(() =>
              diveSite.latitude != null
              && diveSite.longitude != null
              && diveSite.latitude.length !== 0
              && diveSite.longitude.length !== 0
          )
          .map(() => {
            return <Marker
                key={diveSite.id}
                position={[parseFloat(diveSite.latitude).toFixed(6),
                  parseFloat(diveSite.longitude).toFixed(6)]}
            >
              <Popup>
                <span>{diveSite.name}</span>
                <br/>
                <Button onClick={() => handleOpen(diveSite)}>Mer Info</Button>
              </Popup>
            </Marker>
          })}
        </MapContainer>
        <DiveSite diveSiteId={diveSite.id} handleClose={handleClose}
                  handleEdit={handleEdit} handleDelete={handleDelete}
                  open={open}/>
      </div>
  );
}
