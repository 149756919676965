import axios from "axios";
import jwtDecode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL + "/api/auth/";

const login = (username, password) => {
  return axios
  .post(API_URL + "login", {
    'username': username,
    'password': password,
  })
  .then((response) => {
    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data.access_token));
    }

    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getJwt = () => {
  let token = JSON.parse(localStorage.getItem("user"));
  if (token === null) {
    return null;
  }

  let decodedToken = jwtDecode(token);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    logout()
    return null;
  } else {
    return token
  }
};

const resetPassword = (token, password) => {
  return axios
  .post(API_URL + "reset-password", {
    'token': token,
    'password': password,
  })
  .then((response) => {
    return response.data;
  });
}

const lostPassword = (username) => {
  return axios
  .post(API_URL + "lost-password", {
    'username': username,
  })
  .then((response) => {
    return response.data;
  });
}

const lostUsername = (email) => {
  return axios
  .post(API_URL + "lost-username", {
    'email': email
  })
  .then((response) => {
    return response.data
  })
}

const getUserId = () => {
  let token = JSON.parse(localStorage.getItem("user"));
  if (token === null) {
    return false;
  }

  let decodedToken = jwtDecode(token);
  return decodedToken.sub
};

const isLoggedIn = () => {
  let token = JSON.parse(localStorage.getItem("user"));
  if (token === null) {
    return false;
  }

  let decodedToken = jwtDecode(token);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    logout()
    return false;
  } else {
    return true
  }
}

const isLeader = () => {
  let token = JSON.parse(localStorage.getItem("user"));
  if (token === null) {
    return false;
  }

  let decodedToken = jwtDecode(token);
  return decodedToken.isLeader
};

const isAdministrator = () => {
  let token = JSON.parse(localStorage.getItem("user"));
  if (token === null) {
    return false;
  }

  let decodedToken = jwtDecode(token);
  return decodedToken.isAdministrator
};

const AuthService = {
  login,
  logout,
  getJwt,
  resetPassword,
  lostPassword,
  lostUsername,
  getUserId,
  isLoggedIn,
  isAdministrator,
  isLeader,
};

export default AuthService;
