import React, {useEffect, useState} from 'react'
import {Paper, Typography} from "@mui/material"
import eventStatistics from '../../services/statistics-service'
import {makeStyles} from '@material-ui/core/styles'
import {ResponsivePie} from "@nivo/pie";

const useStyles = makeStyles(theme => ({
  root: {
    height: "280px",
    padding: theme.spacing(2)
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 1, 0, 1)
  },
}))

const MembershipStatistics = (props) => {
  const classes = useStyles()
  const [membershipData, setMembershipData] = useState([])

  useEffect(() => {
    eventStatistics.membersByMembership(props.year).then(memberships => {
      const eventData = memberships.map(membership => ({id: membership.name, label: membership.name, value: membership.stats}))
      setMembershipData(eventData)
    })

  }, [props.year])

  return (
    <Paper className={classes.root}>
      <Typography className={classes.heading}>Medlemmar per medlemskap {props.year}</Typography>
      <ResponsivePie
        data={membershipData}
        margin={{top: 40, right: 100, bottom: 40, left: 100}}
        innerRadius={0.6}
        padAngle={0.5}
        cornerRadius={5}
        arcLinkLabelsColor={{
          from: 'color',
        }}
        arcLinkLabelsThickness={3}
        arcLinkLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 1.2]],
        }}
      />
    </Paper>
  )
}

export default MembershipStatistics
