import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel, InputLabel, MenuItem,
  Radio,
  RadioGroup, Select,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material"
import {
  makeStyles,
} from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import authService from "../../services/auth-service";
import UserRoleService from "../../services/user-role-service";
import Loading from "../Loading";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {format, parseISO, addMinutes} from 'date-fns';
import sv from 'date-fns/locale/sv';
import {DateTimeField} from "@mui/x-date-pickers";
import {useSnackbar} from "notistack";
import Utils from "../../services/utils";
import eventService from "../../services/event-service";
import DiveSiteService from "../../services/divesite-service";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alignItems: "left",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: '700px'
  },
  dialogContent: {
    height: "50vh"
  },
  dialogInfo: {
    color: "rgb(255,255,255)",
    background: theme.palette.primary.main,
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  itemIcon: {
    marginRight: theme.spacing(1),
  },
  rte: {
    height: "70%"
  },
  buttonMenu: {
    padding: 5
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  radioGroup: {
    padding: 5,
    margin: 5,
  },

  maxDepth: {
    display: "flex",
    alignItems: "center",
    margin: 10,
  },
  selectList: {
    width: "50%",
    padding: 10,
  },
  selectListFull: {
    width: "100%",
    padding: 10,
  },
  dateTimePicker: {
    width: "50%",
  },
  rowSeperation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10
  }
}));

export default function UppdateEvent(props) {
  const classes = useStyles()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {editDialog, handleEditDialog, id, handleUpdate} = props;
  const [loading, setLoading] = useState(false);
  const [allLeaders, setAllLeaders] = useState([]);
  const [allBoatMasters, setAllBoatMasters] = useState([]);
  const [allDiveSites, setAllDiveSites] = useState([]);
  const {enqueueSnackbar} = useSnackbar()
  const [isValidTimespan, setIsValidTimespan] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(new Date(Date.now() + 2 * 60 * 60 * 1000));
  const [formattedStartDate, setFormattedStartDate] = useState(Date.now());
  const [formattedEndDate, setFormattedEndDate] = useState(new Date(Date.now() + 2 * 60 * 60 * 1000));
  const [boatMaster, setBoatMaster] = useState('');
  const [leader, setLeader] = useState(authService.getUserId);
  const [diveSite, setDiveSite] = useState();
  const [event, setEvent] = useState({
    eventType: '',
    startDate: Date.now(),
    endDate: new Date(Date.now() + 2 * 60 * 60 * 1000),
    title: '',
    description: ''
  });
  const [userId, setUserId] = useState("");
  const [eventType, setEventType] = useState('TRIP');

  const modules = {
    toolbar: [
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleLeader = (e) => {
    const {value} = e.target;
    setLeader(value);
  }

  const handleBoatMaster = (e) => {
    const {value} = e.target;
    setBoatMaster(value);
  }

    const updateStartDate = (value) => {
      setStartDate(value);
    }
    const updateEndDate = (value) => {
      setEndDate(value);
      if (Utils.formatDateTimeFromMillis(endDate) > Utils.formatDateTimeFromMillis(startDate)) {
        setIsValidTimespan(true);
      }
    }
  const handleDiveSite = (event) => {
    setDiveSite(event.target.value);
  }
  const updateDescription = (value) => {
    setDescription(value);
  }
  const updateTitle = (e) => {
    const {value} = e.target;
    setTitle(value);
  }
  const load = () => {

    if (Utils.formatDateTimeFromMillis(endDate) > Utils.formatDateTimeFromMillis(startDate)) {
    setIsValidTimespan(true);
  }
    if (!id) {
      return
    }
    setLoading(true);

    eventService.event(id).then(data => {
      const user = authService.getUserId()
      setUserId(user);
      setEvent(data);
      setTitle(data.title)
      setDescription(data.description)
      setStartDate(data.startDate)
      setEndDate(data.endDate)
      setLeader(data.leader ? data.leader : null)
      setBoatMaster(data.boatMaster ? data.boatMaster : null)
    });

    UserRoleService.getAdminOrLeaders().then(data => {
      setAllLeaders(data);
    });
    UserRoleService.getBoatMasters().then(drivers => {
      setAllBoatMasters(drivers);
    });
    DiveSiteService.all().then(sites => {
      setAllDiveSites(sites);
    });
    setLoading(false);
  }

  useEffect(() => {
    load()
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <Dialog
        open={editDialog}
        fullScreen={fullScreen}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleEditDialog}
        className={classes.root}
      >
        <DialogTitle className={classes.dialogTitle}>Uppdatera Evenemang</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Loading loading={loading}>
            <div className={classes.rowSeperation}/>
            {eventType === "TRIP" ? (
              <div>
                <FormControl className={classes.selectListFull}>
                  <InputLabel id="dive-divesite-label">Dykplats</InputLabel>
                  <Select
                    labelId="select-divesite-label"
                    id="select-divesite"
                    value={diveSite}
                    label="Dykplats"
                    fullWidth={true}
                    onChange={handleDiveSite}
                  >
                    {allDiveSites
                      ? allDiveSites.map((allDiveSite) => (
                        <MenuItem key={allDiveSite.id} value={allDiveSite.id}>
                          {allDiveSite.name}
                        </MenuItem>
                      ))
                      : ''}
                  </Select>
                </FormControl>
                <div className={classes.rowSeperation} />
                <FormControl className={classes.selectList}>
                  <InputLabel id="dive-leader-label">Dykledare</InputLabel>
                  <Select
                    labelId="select-leader-label"
                    id="select-leader"
                    value={leader}
                    label="Dykledare"
                    onChange={handleLeader}
                  >
                    {allLeaders
                      ? allLeaders.map((allLeader) => (
                        <MenuItem key={allLeader.id} value={allLeader.id}>
                          {allLeader.firstname} {allLeader.lastname}
                        </MenuItem>
                      ))
                      : ''}
                  </Select>
                </FormControl>
                <FormControl className={classes.selectList}>
                  <InputLabel id="boat-master-label">Båtförare</InputLabel>
                  <Select
                    labelId="select-boat-label"
                    id="select-boat"
                    value={boatMaster}
                    label="Båtförare"
                    onChange={handleBoatMaster}
                  >
                    {allBoatMasters
                      ? allBoatMasters.map((driver) => (
                        <MenuItem key={driver.id} value={driver.id}>
                          {driver.firstname} {driver.lastname}
                        </MenuItem>
                      ))
                      : ''}
                  </Select>
                </FormControl>
              </div>
            ) : ''}
            <div className={classes.rowSeperation}>
              <DateTimeField
                className={classes.dateTimePicker}
                label="Starttid"
                value={formattedStartDate}
                onChange={(newValue) => updateStartDate(newValue)}
                format="MM/dd/yyyy HH:mm"
              />
              <DateTimeField
                className={classes.dateTimePicker}
                label="Sluttid"
                value={formattedEndDate}
                onChange={(newValue) => updateEndDate(newValue)}
                format="MM/dd/yyyy HH:mm"
              />
            </div>
            <TextField
              id="title"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Titel"
              name="Title"
              value={title}
              onChange={e => updateTitle(e)}
              autoFocus/>

            <ReactQuill className={classes.rte}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        value={description}
                        onChange={e => updateDescription(e)}/>
          </Loading>
        </DialogContent>
        <DialogActions>
          <Button size={"medium"} color="secondary" onClick={handleEditDialog}>Avbryt</Button>
          <Button
            size={"medium"}
            color="primary"
            onClick={() => {
              if (isValidTimespan) {
                handleUpdate({
                  eventId: event.id,
                  title: title,
                  eventType: eventType,
                  description: description,
                  startDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                  endDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
                  boatMaster: boatMaster ? boatMaster : null,
                  leader: leader ? leader : null,
                  diveSite: diveSite
                });
                handleEditDialog()
              } else {
                enqueueSnackbar('Starttid måste vara senare än sluttid', {variant: "warning"})
              }
            }}
          >
            Uppdatera
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
