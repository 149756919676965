import React, {useEffect, useState} from 'react';
import iceService from "../../services/ice-service";
import {makeStyles} from "@material-ui/core";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import ConfirmDialog from "../confirmDialog/ConfirmDialog";


const useStyles = makeStyles((_theme) => ({
  root: {
    width: '100%',
    alignItems: "center",
    flex: 1
  },
  dialogTitle: {
    textAlign: "left",
    minWidth: "700px"
  },
  textInfo: {
    alignItems: "center",
    padding: "8px 24px"
  },
  textWithIcon: {
    alignItems: "center",
  },
  newRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));


export default function InCaseOfEmergency(props) {
  const classes = useStyles();
  const {iceId, handleClose, handleDelete, handleEdit, open} = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [relation, setRelation] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");


  useEffect(() => {
    if (iceId) {
      iceService.get(iceId).then((data) => {
        setName(data.name);
        setRelation(data.relation);
        setPrimaryPhone(data.primaryPhone);
        setSecondaryPhone(data.secondaryPhone);
      })
    }
  }, [open, iceId]);

  const handleSave = () => {
    setEditMode(false)
    handleEdit(iceId , name, relation, primaryPhone, secondaryPhone);
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth={true}
      onClose={handleClose}
      className={classes.root}>
      <DialogTitle className={classes.dialogTitle}>ICE Kontakt: {name}</DialogTitle>
      <DialogContent>
        {!editMode &&
        <div>
          <Typography className={classes.textInfo}>Name: {name}</Typography>
          <Typography className={classes.textInfo}>Relation: {relation}</Typography>
          <Typography className={classes.textInfo}>Primärt telefonnummer: {primaryPhone}</Typography>
          <Typography className={classes.textInfo}>Sekundärt telefonnummer: {secondaryPhone}</Typography>
        </div>}
        {editMode && <TextField
        id="name"
        onChange={(e => setName(e.target.value))}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Namn"
        name="Namn"
        value={name}
        />}
        {editMode && <div className={classes.newRow}>
          <TextField
            id="relation"
            onChange={(e => setRelation(e.target.value))}
            required
            label="relation"
            name="Relation"
            value={relation}
            autoFocus
          />
          <TextField
            id="primaryPhone"
            onChange={(e => setPrimaryPhone(e.target.value))}
            required
            label="primaryPhone"
            name="PrimaryPhone"
            value={primaryPhone}
            autoFocus
          />
          <TextField
            id="secondaryPhone"
            onChange={(e => setSecondaryPhone(e.target.value))}
            required
            label="secondaryPhone"
            name="SecondaryPhone"
            value={secondaryPhone}
            autoFocus
          />
        </div>  }

      </DialogContent>
      <DialogActions>
        {!editMode &&
        <>
          <Button size={"medium"} color="secondary" onClick={() => setConfirmDeleteOpen(true)}>Ta bort</Button>
          <ConfirmDialog
            title="Delete ICE?"
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            onConfirm={handleDelete}
          >
            Är du säker på att du vill radera ICE-kontakten?
          </ConfirmDialog>
          <Button size={"medium"} color="secondary" onClick={() => setEditMode(true)}>Redigera</Button>
          <Button size={"medium"} color="primary" onClick={handleClose}>Stäng</Button>
        </>
        }
        {editMode && <>
          <Button size={"medium"} color="secondary" onClick={handleClose}>Stäng</Button>
          <Button size={"medium"} color="primary" onClick={handleSave}>Spara</Button>
        </>
        }
      </DialogActions>
    </Dialog>
  );
}
