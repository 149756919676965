import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/statistics"

const membersBySection = (year) => {
  return axios
  .get(API_URL + '/members/by-section/' + year, {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
};

const membersByMembership = (year) => {
  return axios
  .get(API_URL + '/members/by-membership/' + year, {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
};

const eventsPlanned = () => {
  return axios
  .get(API_URL + '/event/planned', {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsCanceled = () => {
  return axios
  .get(API_URL + '/event/canceled', {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsCanceledByDiveMasterPerYear = (year) => {
  return axios
  .get(API_URL + '/event/canceled-by-divemaster/' + year, {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsDiveMasterPerYear = () => {
  return axios
  .get(API_URL + '/event/leaders', {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsDiveMasterByYear = (year) => {
  return axios
  .get(API_URL + '/event/leaders/' + year, {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsBoatMasters = () => {
  return axios
  .get(API_URL + '/event/boatmasters', {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const eventsBoatMasterByYear = (year) => {
  return axios
  .get(API_URL + '/event/boatmasters/' + year, {
    headers: authHeader()
  })
  .then((response) => {
    return response.data;
  });
}

const StatisticsService = {
  membersBySection,
  membersByMembership,
  eventsPlanned,
  eventsCanceled,
  eventsCanceledByDiveMasterPerYear,
  eventsDiveMasterPerYear,
  eventsDiveMasterByYear,
  eventsBoatMasters,
  eventsBoatMasterByYear
};

export default StatisticsService
