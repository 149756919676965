import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {useCurrentUser} from "../hooks/login-hook";
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  AccountTree,
  Dashboard,
  Email,
  Event,
  Phonelink,
  Place,
  Settings,
  SupervisorAccountSharp,
  Timeline,
  VerifiedUser
} from "@material-ui/icons";
import {PersonSearch} from "@mui/icons-material";

const categories = [
  {
    id: '',
    children: [
      {id: 'Dashboard', link: '/', icon: <Dashboard/>},
      {id: 'Kalender', link: '/calendar', icon: <Event/>},
      {id: 'Dykplatser', link: '/divesites', icon: <Place/>},
      {id: 'Länkar', link: '/links', icon: <Phonelink/>},
      {id: 'Mailutskick', link: '/mail', icon: <Email/>},
      {id: 'Statistik', link: '/statistics', icon: <Timeline/>},
    ]
  },
  {
    id: 'Mitt konto',
    children: [
      {id: 'Presentation', link: '/my-presentation', icon: <VerifiedUser/>},
      {id: 'Kontouppgifter', link: '/my-account', icon: <Settings/>}
    ]
  },
  {
    id: 'Administration',
    children: [
      {id: 'Medlemmar', link: '/admin/members', icon: <SupervisorAccountSharp/>},
      {id: 'Medlemmar / Sektion', link: '/admin/members-by-section', icon: <SupervisorAccountSharp/>},
      {id: 'Medlems Sök', link: '/admin/member/search', icon: <PersonSearch/>},
      {id: 'Roller', link: '/admin/roles', icon: <AccountTree/>},
    ]
  },
];

const styles = (theme) => ({
  logo: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const {classes, ...other} = props;
  const currentUser = useCurrentUser();
  const renderLoggedInNavigation = (
    <List disablePadding>
      {categories.map(({id, children}) => (
        <React.Fragment key={id}>
          <ListItem button className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              {id}
            </ListItemText>
          </ListItem>
          {children.map(({id: childId, link, icon, active}) => (
            <ListItem
              key={childId}
              button
              component="a"
              href={link}
              className={clsx(classes.item, active && classes.itemActiveItem)}
            >
              <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {childId}
              </ListItemText>
            </ListItem>
          ))}

          <Divider className={classes.divider}/>
        </React.Fragment>
      ))}
    </List>
  )

  return (
    <Drawer variant="permanent" {...other}>
      <Grid container justifyContent="center" className={classes.logo}>
        <Grid item><img src={'/logo144.png'} alt=""/></Grid>
      </Grid>
      {currentUser ? renderLoggedInNavigation : ''}
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
