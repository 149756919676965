import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "/api/link"
const create = (url) => {
  return axios
    .post(API_URL, {
      'url': url
    },{
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getAll = () => {
  return axios
    .get(API_URL, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const LinkService = {
  getAll,
  create
};

export default LinkService
