import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth-service";

const API_URL = process.env.REACT_APP_API_URL + "/api/member";
const USER_ID = authService.getUserId();

const myself = () => {
  return axios
    .get(API_URL + "/myself", {headers: authHeader()})
    .then((response) => {
      return response.data;
    });
};

const getUserMemberships = (userId) => {
  return axios
    .get(API_URL + "/" + userId + "/memberships", {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
};

const getCertificates = (userId) => {
  return axios
    .get(API_URL + "/" + userId + "/certificates", {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const removeCertificate = (userId, certificateId) => {
  return axios
    .delete(API_URL + "/" + userId + "/certificates/" + certificateId, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const addCertificate = (userId, certificateId) => {
  return axios
    .post(API_URL + "/" + userId + "/certificates/" + certificateId, {id: certificateId}, {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const createCertificate = (organization, course, weight, description) => {
  return axios
    .post(API_URL + "/" + USER_ID + "/certificates", {userId: USER_ID, organization: organization, course: course, weight: weight, description: description},
      {headers: authHeader()})
    .then((response) => {
      return response.data;
    })
}

const getInvoice = (membershipId) => {
  return axios
    .get(API_URL + "/" + USER_ID + "/invoice/" + membershipId, {headers: authHeader(), responseType: "blob"})
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "msdk-kvitto-" + USER_ID + membershipId + ".pdf");
      document.body.appendChild(link);
      link.click();
    })
}

const MemberService = {
  myself,
  getUserMemberships,
  getCertificates,
  addCertificate,
  createCertificate,
  removeCertificate,
  getInvoice
}

export default MemberService
