import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import MemberService from "../../services/member-service";
import authService from "../../services/auth-service";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(2, 2, 0, 2)
  },
  container: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  scroll: {
    overflow: "scroll",
    maxHeight: "80vh"
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));
export default function UserMembershipsAndSections() {

  const classes = useStyles();
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    MemberService.getUserMemberships(authService.getUserId())
    .then(data => {
      console.log(data)
      setMemberships(data)
    })
    setLoading(false);
  }, [])

  return (
      <Paper sx={{overflow: 'hidden'}}>

        <div className={classes.topRow}>
          <Typography className={classes.heading}>Medlemskap och
            Sektioner</Typography>
        </div>
        <Loading loading={loading}/>
        <TableContainer component={Paper} sx={{maxHeight: "80vh", margin: '5px', width: '98%'}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Årtal</TableCell>
                <TableCell>Sektion</TableCell>
                <TableCell>Medlemskap</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memberships
              .map((membership, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{membership.period}</TableCell>
                        <TableCell>{membership.section}</TableCell>
                        <TableCell>{membership.name}</TableCell>
                        <TableCell>
                          <Button
                            startIcon={<DownloadIcon/>}
                            size="small"
                            variant="outlined"
                            onClick={() => MemberService.getInvoice(membership.id)}
                          >
                            Kvitto
                          </Button>
                        </TableCell>
                      </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
  )
}
