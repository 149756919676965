import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth-service";

const userId = authService.getUserId();
const API_URL = process.env.REACT_APP_API_URL  + `/api/${userId}/bio`;

const get = () => {
  return axios
    .get(API_URL, {headers: authHeader()})
    .then((response) => {
      return response.data
    })
}

const create = (bio) => {
  return axios
    .post(API_URL, {biography: bio}, {headers: authHeader()})
    .then((response) => {
      return response.data
    })
}

const remove = () => {
  return axios
    .delete(API_URL, {headers: authHeader()})
    .then((response) => {
      return response.data
    })
}

const UserBioService = {
  get,
  create,
  remove
}
export default UserBioService;
